import React, { useEffect} from 'react';
import './LogAnalysis.css';
import { useNavigate } from "react-router-dom";
import Report from './Report';

function LogAnalysis() {
  const navigate = useNavigate();
  const isDataAvailable = sessionStorage.getItem('graphData') ? true : false;
  const fligthGraphData= JSON.parse(sessionStorage.getItem('graphData'));
  const flightInfo = JSON.parse(sessionStorage.getItem('flightInfo'));
  const flightLogFile= JSON.parse(sessionStorage.getItem('flightLogFile'));

  if(!sessionStorage.getItem("graphData")){
    navigate("/dashboard");
  } else {
    console.log(fligthGraphData);
  
  }
  useEffect(()=>{
    let a = sessionStorage.getItem("graphData")
    if(a === null){
        navigate("/dashboard")
    }
},[])

  return (
    <main className="analysis-wrap">
        <div className='graph-content-wrapper'>
            {isDataAvailable &&
            <Report rData={fligthGraphData} fileName={flightLogFile} flightinfo={flightInfo}/>
        }    
        </div>
    </main>
  )
}

export default LogAnalysis