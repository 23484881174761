import { useState, useEffect } from "react";
import {  Scatter, } from "react-chartjs-2";
import { AltColor, AxisBorderColor, AxisGridColor, AxisTickColor, AxisTitleColor, RunwayColor, VsColor } from "../../../ColorConst/Graphcolors";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  Filler,
} from 'chart.js';
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  Filler
);



export default function TakeOFF(props) {

  const [altProfileData, setaltProfileData] = useState();
  const [altSatus, setaltSatus] = useState(false);

  const [VsSpdStatus, setVsSpdStatus] = useState();
  const [VspdStatus, setVspdStatus] = useState(false);
  const [IsPhaseVisible, setIsPhaseVisible] = useState(false);

  const crNO = props.crNO;
  function Plot() {
    let takeoffData = props.data;
    setIsPhaseVisible(props.data.isVisible ? true : false);
    if (takeoffData.takeoff_detected === 1) {

      if (takeoffData["Altitude Profile"].status === 1) {

        let xValues = takeoffData["Altitude Profile"].x;
        let dataArray = [];
        for (let index = 0; index < xValues.length; index++) {
          let tempXY = {
            x: xValues[index],
            y: takeoffData["Altitude Profile"].y[index]
          }
          dataArray.push(tempXY);
        }

        if (takeoffData["Altitude Profile"].runway !== 0) {
          let minValue = Math.min(...takeoffData["Altitude Profile"].y);
          let runwayData = [{ x: xValues[0], y: minValue }, { x: takeoffData["Altitude Profile"].runway, y: minValue }]

          let spdTempdata = {
            datasets: [
              {
                label: 'Altitude',
                data: dataArray,
                showLine: true,
                fill: false,
                borderColor: AltColor,
                pointRadius: 0.5,

              },
              {
                label: '',
                data: runwayData,
                showLine: true,
              //  fill: 'below',
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)',
                fill: {
                  target: 'origin',
                  above: '#888888',   // Area will be red above the origin
                  below: 'rgb(0, 0, 255)'    // And blue below the origin
                },
                pointRadius: 0,
              }

            ]

          }
          setaltProfileData(spdTempdata);
          setaltSatus(true);
        } else {

          let spdTempdata = {
            datasets: [
              {
                label: 'Altitude',
                data: dataArray,
                showLine: true,
                fill: false,
                borderColor: AltColor,
                pointRadius: 0.2,

              },]
          }
          setaltProfileData(spdTempdata);
          setaltSatus(true);
        }


      }
      if (takeoffData["Vertical Speed Profile"].status === 1) {
        let xValues = takeoffData["Vertical Speed Profile"].x;
        let dataArray = [];
        for (let index = 0; index < xValues.length; index++) {
          let tempXY = {
            x: xValues[index],
            y: takeoffData["Vertical Speed Profile"].y[index]
          }
          dataArray.push(tempXY);
        }


        let spdTempdata = {
          datasets: [
            {
              label: 'Vertical Speed',
              data: dataArray,
              showLine: true,
              fill: false,
              borderColor: VsColor,
              pointRadius: 0.5,

            }
          ]
        }

        if (takeoffData["Vertical Speed Profile"].runway !== 0) {
          // For runway
          let minValue = Math.min(...takeoffData["Vertical Speed Profile"].y);
          let runwayY = 0;
          let axisTarget=0;
          if (minValue < -500 && minValue>=-1000) {
            runwayY = -950;
            axisTarget=-1000;
          }
          else if (minValue >= -500) {
            runwayY = -450;
            axisTarget=-500;
          }
          else{
            runwayY = minValue+50;
            axisTarget=minValue;
          }


          let runwayData = [{ x: xValues[0], y: runwayY }, { x: takeoffData["Vertical Speed Profile"].runway, y: runwayY }];
          let runwayTemp =  {
            label: '',
            data: runwayData,
            showLine: true,
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            fill: {
              target: {value : axisTarget},
              above: RunwayColor,  
            },
            pointRadius: 0,
          }


          spdTempdata.datasets.push(runwayTemp)
        }
        setVsSpdStatus(spdTempdata);
        setVspdStatus(true);
      }


    }
  }

  const AltOptions = {
    responsive: true,
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      x: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: {
          values: [0, 1, 2, 3, 4], color: 'rgba(69, 71, 95, 1)', maxTicksLimit: 9,
         
        },
        title: {
          display: true,
          text: "Distance from Runway (NM)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { color: 'rgba(69, 71, 95, 1)', maxTicksLimit: 6 },
        title: {
          display: true,
          text: "AltMSL (ft)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },
    plugins: {
      tooltip: {
        interaction: {
          mode: 'nearest',
          intersect: true
      }
      }
    },
    layout: {
      padding: {

      }
    },
    
  }
  const VSpeedProfileOptions = {
    responsive: true,
    scales: {
      x: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,
          
        },
        title: {
          display: true,
          text: "Distance from Runway (NM)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { color: AxisTickColor, maxTicksLimit: 6, stepSize: 500 },
        title: {
          display: true,
          text: "Vertical Speed (fpm)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },
  }
  useEffect((e) => {
    Plot();
  }, []);
  useEffect(() => {
    setIsPhaseVisible(props.data.isVisible ? true : false);
  }, [props.data.isVisible])
  return (
    <div className={IsPhaseVisible ? '' : 'd-none'}>
      {altSatus &&
        <div className="single-graph-wrapper" id={`cr${crNO}_TO_Altwr`} key={`cr${crNO}_TO_Altwr`}>
          <div className="GraphHeading" key={`cr${crNO}_TO_AltHDG`}>Circuit {crNO} Take off Phase: Altitude Profile</div>
          <Scatter id={`cr${crNO}_TO_Alt`} data={altProfileData} options={AltOptions} key={`cr${crNO}_TO_Alt`}/>

        </div>
      }
      {VspdStatus &&
        <div className="single-graph-wrapper" id={`cr${crNO}_TO_VSwr`} key={`cr${crNO}_TO_VSwr`}>
          <div className="GraphHeading"  key={`cr${crNO}_TO_VSHDG`}>Circuit {crNO} Take off Phase: Vertical Speed Profile</div>
          <Scatter id={`cr${crNO}_TO_VS`} data={VsSpdStatus} options={VSpeedProfileOptions} key={`cr${crNO}_TO_VS`}/>

        </div>
      }
    </div>
  )
}