import "./CesiumKml.css";
import { Viewer, Scene,ScreenSpaceCameraController } from "resium";
import { Ion, createWorldTerrain, Cartesian3, Color, VerticalOrigin, Math } from "cesium";
import { useEffect, useState } from "react";
import _, { forEach } from "lodash";
import { PhaseColor } from "../../ColorConst/Graphcolors";


Ion.defaultAccessToken = process.env.REACT_APP_Cesium_Token;
//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1NjQwM2RlYS0wZjBkLTRjMGYtOGU4MS0yMzc0OGVjOWU5MmMiLCJpZCI6MTM1NDQxLCJpYXQiOjE2ODc1MDY4MDB9.QwFXF5Ea5Q0efEUAtqniuvxaW8u4riw-6JXHD6CSzQE';
const terrianProvider = createWorldTerrain();
export default function (props) {
    const [phasesFilterItems, setPhasesFilterItems] = useState([])
    const [PhaseItems, setPhaseItems] = useState({});
    let viewer;


    const onPhaseFilterItemSelected = (selectedPhaseItem) => {

      
        selectedPhaseItem.isVisible = !selectedPhaseItem.isVisible;
        setPhasesFilterItems([...phasesFilterItems]);
        const selectedPhasesArr = _.filter(phasesFilterItems, (phaseFilter) => phaseFilter.isVisible);
        const isFilterApplied = selectedPhasesArr.length > 0;
        if (isFilterApplied) {
            phasesFilterItems.forEach(element => {
                PhaseItems[element.PhaseName].forEach(e => {
                    e.show = element.isVisible;
                })
            });
        } else {
            phasesFilterItems.forEach(element => {
                PhaseItems[element.PhaseName].forEach(e => {
                    e.show = true;
                })

            });
        }


    };

    function SeparateData() {
        let KmlDataArray = props.graphData.data_kml;
        let PTOPhase = [];
        let TOPhase = [];
        let CruisePhase = [];
        let LandingPhase = [];
        let DescentPhase = [];
        let ClimbPhase = [];
        let ApproachPhase = [];
        let phaseItems = {};
        let PhaseFilter = [];
        KmlDataArray.forEach(function (item, index, arr) {
            let phases = [];
            if (arr[index + 1]) {
                if (item.taxi !== "") {
                    PTOPhase.push(AddWall(item, index, arr, "PTO"))
                    phases.push({ PhaseName: "Pre T/O Phase", isVisible: false });
                } else if (item.takeoff !== "") {
                    TOPhase.push(AddWall(item, index, arr, "TO"))
                    phases.push({ PhaseName: "Takeoff Phase", isVisible: false });
                }
                else if (item.landing !== "") {
                    LandingPhase.push(AddWall(item, index, arr, "Land"))
                    phases.push({ PhaseName: "Landing Phase", isVisible: false });
                }
                else if (item.cruise !== "") {
                    CruisePhase.push(AddWall(item, index, arr, "Cruise"))
                    phases.push({ PhaseName: "Cruise Phase", isVisible: false });
                    
                }
                else if (item.descent !== "") {
                    DescentPhase.push(AddWall(item, index, arr, "Descent"));
                    phases.push({ PhaseName: "Descent Phase", isVisible: false });
                }
                else if (item.climb !== "") {
                    ClimbPhase.push(AddWall(item, index, arr, "Climb"));
                    phases.push({ PhaseName: "Climb Phase", isVisible: false });
                }
                else if (item.approach !== "") {
                    ApproachPhase.push(AddWall(item, index, arr, "Approach"));
                    phases.push({ PhaseName: "Approach Phase", isVisible: false });
                }
                PhaseFilter = _.unionBy(PhaseFilter, phases, "PhaseName")
            }
        });

        if (PTOPhase.length > 0) phaseItems["Pre T/O Phase"] = PTOPhase;
        if (TOPhase.length > 0) phaseItems["Takeoff Phase"] = TOPhase;
        if (LandingPhase.length > 0) phaseItems["Landing Phase"] = LandingPhase;
        if (CruisePhase.length > 0) phaseItems["Cruise Phase"] = CruisePhase;
        if (DescentPhase.length > 0) phaseItems["Descent Phase"] = DescentPhase;
        if (ApproachPhase.length > 0) phaseItems["Approach Phase"] = ApproachPhase;
        if (ClimbPhase.length > 0) phaseItems["Climb Phase"] = ClimbPhase;
        setPhaseItems(phaseItems);
        setPhasesFilterItems(PhaseFilter)

        let e = viewer.entities.add({
            position: Cartesian3.fromDegrees(+KmlDataArray[0].Longitude, +KmlDataArray[0].Latitude, 1000),
            point: {
                verticalOrigin: VerticalOrigin.CENTER

            }
        });
        let allThePts = [...phaseItems["Pre T/O Phase"], ...phaseItems["Takeoff Phase"], ...phaseItems["Landing Phase"], ...phaseItems["Cruise Phase"], ...phaseItems["Descent Phase"],
        ...phaseItems["Approach Phase"], ...phaseItems["Climb Phase"]]
        viewer.zoomTo(allThePts);
        setTimeout(() => {
            props.loadSpinner();
        }, 4000)

    }

    function AddWall(item, index, arr, Clr) {
        let CesiumClr = PhaseColor[Clr];
        let wall = viewer.entities.add({
            name: "wall",
            wall: {
                positions: Cartesian3.fromDegreesArrayHeights([
                    +item.Longitude,
                    +item.Latitude,
                    +item.AltGPS,
                    +arr[index + 1].Longitude,
                    +arr[index + 1].Latitude,
                    +arr[index + 1].AltGPS
                ]),
                material: CesiumClr,
                outline: true,
                outlineWidth: 2,
                outlineColor: CesiumClr
            },


        });
        return wall;
    }

    
    useEffect(() => {
        SeparateData();
    }, []);

    return (
        <div>

            <div className="GraphContainer">
                <div className="graph-filters-wrap">

                    {phasesFilterItems.map(phasesFilterItem => (
                        <div style={{display:"inline-block"}}
                            className={"graph-filter-item " + (phasesFilterItem.isVisible ? 'selected' : '')}
                            onClick={() => onPhaseFilterItemSelected(phasesFilterItem)}
                        >
                            {phasesFilterItem.PhaseName}
                        </div>
                    ))
                    }

                    <div className="single-graph-wrapper" >

                        <Viewer defaultAccessToken={Ion}
                            timeline={false}
                            animation={false}
                            fullscreenButton={false}
                            vrButton={false}
                            // navigationHelpButton={false}
                            navigationInstructionsInitiallyVisible={false}
                            homeButton={false}
                            // selectionIndicator={false}
                            ref={e => {
                                viewer = e && e.cesiumElement;
                            }}
                            terrainProvider={terrianProvider}

                        >
                         
                            {/* <KmlDataSource data={kmlData} show={true} onLoad={handleLoad} /> */}


                        </Viewer>
                    </div>
                </div>

            </div>

        </div>

    )
}