// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    background-color: rgb(31, 31, 31);
    display: flex;
    justify-content: space-between;
}
.btn-logout{
   padding: 3px 14px;
   align-self: center;
   margin-right: 3%;
   background-color: rgb(31, 31, 31);
   border: none;
   height: 25px;
   cursor: pointer;
}

.logout-img {
  height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,aAAa;IACb,8BAA8B;AAClC;AACA;GACG,iBAAiB;GACjB,kBAAkB;GAClB,gBAAgB;GAChB,iCAAiC;GACjC,YAAY;GACZ,YAAY;GACZ,eAAe;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".header{\n    background-color: rgb(31, 31, 31);\n    display: flex;\n    justify-content: space-between;\n}\n.btn-logout{\n   padding: 3px 14px;\n   align-self: center;\n   margin-right: 3%;\n   background-color: rgb(31, 31, 31);\n   border: none;\n   height: 25px;\n   cursor: pointer;\n}\n\n.logout-img {\n  height: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
