import { useState, useEffect } from "react";
import { Line,  } from "react-chartjs-2";
import { AltColor, AxisBorderColor, AxisGridColor, AxisTickColor, AxisTitleColor, HDGColor,  IdealColor, LowerLimitColor, UpperLimitcolor } from "../../../ColorConst/Graphcolors";
import Annotation from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  Filler,
} from 'chart.js';
import { average } from "../../../helper/savePdf";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  Filler,
  Annotation
);


export default function CruisePhase(props) {

  //For NUMBER ARRAY
  const [numArray, setNumarray] = useState([]);
  //FOR Altitude Profile
  const [AltProfileDataArray, setAltprfArray] = useState();
  const [AltProfileStatusArr, setAltProfileStatusArr] = useState([]);
  const [AltRemarkArray, setAltRemarkArray] = useState([]);

  //For Heading Profile
  const [HDGDataArray, setHDGdataArray] = useState();
  const [HDGStatusArray, setHDGStatusArray] = useState([]);
  const [HDGReamarksArray, setHDGReamarksArray] = useState([]);

  //For IAS AND GS
 const [IsPhaseVisible, setIsPhaseVisible] = useState(false);

  const crNO = props.crNO;


  function SetData() {
    let CRPhData = props.data;
    setIsPhaseVisible(props.data.isVisible ? true : false);
    let count = CRPhData.count;
    //console.log(CRPhData)
    //Numarray
    let numberArray = [];

    let AltPrDataArray = [];
    let AltStatusArray = [];
    let AltRemarkArr = [];

    //For HEADING
    let HDGdataArr = [];
    let HDGStatusArr = [];
    let HDGremarkArr = [];

    //For Spd


    for (let index = 1; index <= count; index++) {
      let CountData = CRPhData["" + index];

      //Altitude profile
      if (CountData["Altitude Profile"].status === 1) {
        let AvgYValue = average(CountData["Altitude Profile"].y);

        let AltPrXVal = CountData["Altitude Profile"].x;
        let xArray = [];
        let xAvgArr = [];
        let xUpperLtArr = [];
        let xLowerLtArr = [];
        for (let index = 0; index < AltPrXVal.length; index++) {
          let xVal = AltPrXVal[index].split(" ");
          xArray.push(xVal[1]);
          xAvgArr.push(AvgYValue);
          xUpperLtArr.push(AvgYValue + 450);
          xLowerLtArr.push(AvgYValue - 450);

        }

        let tempAltPrData = {
          labels: xArray,
          datasets: [{
            label: 'Actual',
            data: CountData["Altitude Profile"].y,
            borderColor: AltColor,
            borderWidth: 2,
            pointRadius: 0,

          },
          {
            label: 'Ideal',
            data: xAvgArr,
            showLine: true,
            fill: false,
            borderWidth: 2,
            borderColor: IdealColor,
            pointRadius: 0,

            borderDash: [10, 5],

          },
          {
            label: 'Upper Limit',
            data: xUpperLtArr,
            showLine: true,
            borderColor: UpperLimitcolor,
            borderWidth: 2,
            fill: false,
            pointRadius: 0,
            borderDash: [10, 5],


          },
          {
            label: 'Lower Limit',
            data: xLowerLtArr,
            showLine: true,
            fill: false,
            borderWidth: 2,
            borderColor:LowerLimitColor,
            borderDash: [10, 5],
            pointRadius: 0,

          },

          ]
        }
        AltPrDataArray.push(tempAltPrData);
        AltStatusArray.push(true);
        AltRemarkArr.push(CountData["Altitude Profile"].auto_comment)
      }
      else {
        AltPrDataArray.push([]);
        AltStatusArray.push(false);
      }

      //Heading profile
      if (CountData["Heading Profile"].status === 1) {
        let AvgYValue = average(CountData["Heading Profile"].y);

        let HDGX = CountData["Heading Profile"].x;
       
        let xArray = [];
        let xAvgArr = [];
        let xUpperLtArr = [];
        let xLowerLtArr = [];
        for (let index = 0; index < HDGX.length; index++) {
          let xVal = HDGX[index].split(" ");
          xArray.push(xVal[1]);

          xAvgArr.push(AvgYValue);
          xUpperLtArr.push(AvgYValue + 20);
          xLowerLtArr.push(AvgYValue - 20);

        }
        let tempHDGData = {
          labels: xArray,
          datasets: [{
            label: 'Actual',
            data: CountData["Heading Profile"].y,
            borderColor: HDGColor,
            borderWidth: 2,
            pointRadius: 0.5,

          },
          {
            label: 'Ideal',
            data: xAvgArr,
            showLine: true,
            fill: false,
            borderWidth: 2,
            borderColor: IdealColor,
            pointRadius: 0,

            borderDash: [10, 5],

          },
          {
            label: 'Upper Limit',
            data: xUpperLtArr,
            showLine: true,
            borderColor: UpperLimitcolor,
            borderWidth: 2,
            fill: false,
            pointRadius: 0,
            borderDash: [10, 5],


          },
          {
            label: 'Lower Limit',
            data: xLowerLtArr,
            showLine: true,
            fill: false,
            borderWidth: 2,
            borderColor: LowerLimitColor,
            borderDash: [10, 5],
            pointRadius: 0,

          },

          ]
        }

        HDGdataArr.push(tempHDGData);
        HDGStatusArr.push(true);
        HDGremarkArr.push(CountData["Heading Profile"].auto_comment);;
      } else {
        HDGdataArr.push([]);
        HDGStatusArr.push(false);
      }


      numberArray.push(index - 1);

    }
    setAltprfArray(AltPrDataArray);
    setAltProfileStatusArr(AltStatusArray)
    setAltRemarkArray(AltRemarkArr)

    setHDGdataArray(HDGdataArr);
    setHDGStatusArray(HDGStatusArr);
    setHDGReamarksArray(HDGremarkArr);

    setNumarray(numberArray);

  }

  const AltPrOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: AxisGridColor,
          /// drawOnChartArea:false,
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        title: {
          display: true,
          text: "Elapsed Time (HH:MM:SS)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },

      },
      y: {
        grace: "25%",
        grid: {
          color: AxisGridColor,
          //  drawOnChartArea:false,

        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { color: AxisTickColor, },

        title: {
          display: true,
          text: "AltMSL (ft)",
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },
    // maintainAspectRatio: false,
    layout: {
      padding: {
        top: 5,
        left: 5,
        right: 45,
        bottom: 15
      }
    },
    plugins: {
      title: {
        display: false,
        text: 'Custom Chart Title'
      },
      chartAreaBorder: {
        borderColor: 'red',
        borderWidth: 2,
        borderDash: [5, 5],
        borderDashOffset: 2,
      }
    }

  }
 
  const CrHDGOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,
        },

        title: {
          display: true,
          text: "Elapsed Time (HH:MM:SS)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {

        max: 360,
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { color: AxisTickColor, stepSize: 60 },
        title: {
          display: true,
          text: "Heading (deg)",
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },
    // maintainAspectRatio: false,
    layout: {
      padding: {
        top: 5,
        left: 5,
        right: 45,
        bottom: 15
      }
    },

    // plugins: {
    // annotation: {
    //     annotations: {
    //       annotation1,

    //     }
    //   }
    // }
  }

 
  useEffect((e) => {
    SetData();
  }, []);
  useEffect(() => {
    setIsPhaseVisible(props.data.isVisible ? true : false);
  }, [props.data.isVisible])
  return (
    <div className={IsPhaseVisible ? '' : 'd-none'}>
      {numArray.map(e => (
        <div >
          <div >
            {AltProfileStatusArr[e] &&
              <div className="single-graph-wrapper" id={`cr${crNO}_Cruise_${e + 1}_Altwr`} key={`cr${crNO}_Cruise_${e + 1}_Altwr`} >
                <div className="GraphHeading" key={`cr${crNO}_Cruise_${e + 1}_AltHD`}>Circuit {crNO} Cruise Phase {e+1}: Altitude Profile</div>
                <Line id={`cr${crNO}_Cruise_${e + 1}_Alt`} key={`cr${crNO}_Cruise_${e + 1}_Alt`} data={AltProfileDataArray[e]} options={AltPrOptions} />
                <div className={`remarks ${AltRemarkArray[e] === "Satisfactory" ? "satisfactory" : "notsatisfactory"}`} key={`cr${crNO}_Cruise_${e + 1}_AltRm`}>Remark: {AltRemarkArray[e]}</div>
              </div>
            }
            {HDGStatusArray[e] &&
              <div className="single-graph-wrapper" id={`cr${crNO}_Cruise_${e + 1}_HDGwr`} key={`cr${crNO}_Cruise_${e + 1}_HDGwr`}>
                <div className="GraphHeading" key={`cr${crNO}_Cruise_${e + 1}_HDGHD`}>Circuit {crNO} Cruise Phase {e+1}: Heading Profile</div>
                <Line id={`cr${crNO}_Cruise_${e + 1}_HDG`} key={`cr${crNO}_Cruise_${e + 1}_HDG`} data={HDGDataArray[e]} options={CrHDGOptions} />
                <div className={`remarks ${HDGReamarksArray[e] === "Satisfactory" ? "satisfactory" : "notsatisfactory"}`} key={`cr${crNO}_Cruise_${e + 1}_HDGRm`}>Remark: {HDGReamarksArray[e]}</div>
              </div>
            }
          
          </div>
        </div>
      ))}
    </div>
  )
}