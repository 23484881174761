import { Color } from "cesium";

export const AltColor="#7856ff";
export const GSspeedColor="#CA80DC"
export const HDGColor="#3BA974";
export const IASColor="rgba(245, 141, 145, 0.8)"
export const Pitchcolor="#FF7557"
export const VsColor="#B2596E";
export const AxisGridColor="#E6E6E6";
export const AxisBorderColor="#8d8d8d";
export const AxisTickColor="rgba(69, 71, 95, 1)";
export const AxisTitleColor="#161616";
export const RunwayColor="#888888"
export const UpperLimitcolor=" #093BC1";
export const LowerLimitColor="#DD731C";
export const IdealColor="#06B1D7"
export const XToucDownColor="rgba(255, 99, 255, 1)"

export const PhaseColor={
    PTO:Color.fromCssColorString("rgba(200, 224, 103, 0.4)"),
    TO:Color.fromCssColorString("rgba(169, 73, 204, 0.4) "),
    Land:Color.fromCssColorString("rgba(245, 205, 113, 0.4)"),
    Approach:Color.fromCssColorString("rgba(49, 165, 245, 0.4)"),
    Cruise:Color.fromCssColorString("rgba(10, 10, 245, 0.4)"),
    Descent:Color.fromCssColorString("rgba(138, 6, 138, 0.4)"),
    Climb:Color.fromCssColorString("rgba(7, 163, 7, 0.4)")
}