
import AuthService from "../Services/auth.service";
import jsPDF from "jspdf";
import AerxLogoBlack from '../Images/AerxLogoBlack.png'
import frontPageBg from '../Images/report background image.jpg'
import AerxLogo from '../Images/aerx-logo@3x.png'
import '../assets/fonts/Js-Fonts/IBMPlexSans-Bold-bold'
import '../assets/fonts/Js-Fonts/IBMPlexSans-Regular-normal'


const SavePdf = (graphData, printInfo, fullFileName, stopSpinnerCallBack) => {


	//current dateTime for file name 
	const date = new Date();
	let day = ("0" + date.getDate()).slice(-2)
	let month = ("0" + (date.getMonth() + 1)).slice(-2)
	let year = date.getFullYear();
	let hours = ("0" + date.getHours()).slice(-2)
	let min = ("0" + date.getMinutes()).slice(-2)
	let sec = date.getSeconds();

	let pageNum = []
	let pageNumber = 0
	let remarkPage = 0
	let firstGraph = 0
	//image logo for pdf page header
	var logo = new Image()
	logo.src = AerxLogoBlack

	let whiteLogo = new Image()
	whiteLogo.src = AerxLogo
	//jspdf code started here
	const report = new jsPDF('landscape', 'pt', 'a4');
	pageNumber ++
	let width = report.internal.pageSize.getWidth()
	let height = report.internal.pageSize.getHeight()

	//report front page showing flight info
	report.setFillColor(18, 18, 18)
	report.rect(0, 0, width, height, 'F')
	let bgImg = new Image();
	bgImg.src = frontPageBg;
	report.addImage(bgImg, 'png', 0, 0, width, height, undefined, 'FAST');
	
	report.addImage(whiteLogo, 'png', 47.8, 243.7, 172, 17);
	report.setFontSize(32)
	report.setTextColor(255, 255, 255)
	report.setFont('IBMPlexSans-Bold', 'bold')
	report.text(47.8, 300.7, 'Flight Log Analysis Report')

	report.setFont('IBMPlexSans-Bold', 'bold')
	report.setFontSize(16)
	report.text(47.8, 350.7, "Flight Date :")
	//report.text(47.8, 462.7, "Pilot's Name :")
	report.text(47.8, 420.7, "Pilot's Name :")
	report.text(49, 484.7, "Exercise :")

	report.text(307.8, 420.7, "Departure Time :")
	report.text(307.8, 484.7, "Arrival Time :")
	
	report.text(557.8, 420.7, "Duration :")
	report.text(557.8, 484.7, "Landings :")

	report.setFont('IBMPlexSans-Regular', 'normal')
	report.text(47.8, 448.7, printInfo[0])
	report.text(142.8, 350.7, printInfo[1])
	report.text(303.8, 448.7, printInfo[2])
	report.text(303.8, 512.7, printInfo[3])
	
	report.text(559.8, 448.7, printInfo[4])
	report.text(47.8, 512.7, printInfo[5])
	report.text(559.8, 512.7, printInfo[6].toString())
	
	//dummy report summary
	let dummy = []
	remarkSummary(logo, report, graphData, dummy);


	//Flight Elevation Profile
	if (graphData['Flight Elevation Profile'].status === 1) {
		newPage(logo, 
			report, 
			"Flight Elevation Profile",
			null,
			null,
			null,
			null ,
			null, 
			"EleGraph")
			pageNumber ++
			if(firstGraph === 0){
				firstGraph = report.internal.getCurrentPageInfo().pageNumber
			}
	}


	//Circuits graphs
	let cktNum = graphData.Total_circuits

	for (let i = 1; i <= cktNum; i++) {
		//if (graphData['Circuit ' + i].graph_data_status == 1) {

			//Pre T/O Phase

			//Taxi Speed: Pre Takeoff
			if (graphData['Circuit ' + i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].status === 1 && graphData['Circuit ' + i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].touch_and_go !== 1) {
				
				newPage(logo, 
					report, 
					"Pre Take off phase", 
					"Taxi Speed Profile",
					190,
					'Circuit ' + i ,
					330,
					graphData['Circuit ' + i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment, 
					"cr" + i + "_PTO_Tax")
					pageNumber ++
					if(graphData['Circuit ' + i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment !== "Satisfactory"){
						pageNum.push(pageNumber)
					}
					if(firstGraph === 0){
						firstGraph = report.internal.getCurrentPageInfo().pageNumber
					}

			}

			//Takeoff Phase
			if (graphData['Circuit ' + i]['Takeoff Phase'].takeoff_detected === 1) {

				//Speed Profile
			
				if (graphData['Circuit ' + i]['Takeoff Phase']['Altitude Profile'].status === 1) {
				
					newPage(logo, 
						report, 
						"Takeoff Phase", 
						"Altitude Profile",
						160,
						'Circuit ' + i ,
						270,
						null, 
						"cr" + i + "_TO_Alt")
						pageNumber ++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
				}

				//Vertical Speed Profile
				if (graphData['Circuit ' + i]['Takeoff Phase']['Vertical Speed Profile'].status === 1) {
					newPage(logo, 
						report, 
						"Takeoff Phase", 
						"Vertical Speed Profile",
						160,
						'Circuit ' + i,
						320,
						null, 
						"cr" + i + "_TO_VS")
						pageNumber++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
				}
			}

			//Climb Phase
			let climbPhaseNum = graphData['Circuit ' + i]['Climb Phase'].count
			for (let j = 1; j <= climbPhaseNum; j++) {

				//Altitude Profile
				if (graphData['Circuit ' + i]['Climb Phase'][j]['Altitude Profile'].status === 1) {
					newPage(logo, 
						report, 
						"Climb Phase "+j, 
						"Altitude Profile",
						160,
						'Circuit ' + i,
						270,
						null, 
						"cr" + i + "_Climb_" + j + "_Alt")
						pageNumber++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
				
				}

				//Speed and Vspeed Profile
				if (graphData['Circuit ' + i]['Climb Phase'][j]['IAS and Vs Profile'].status === 1) {
					newPage(logo, 
						report, 
						"Climb Phase "+j, 
						"IAS and Vs Profile",
						160,
						'Circuit ' + i,
						300,
						null, 
						"cr" + i + "_Climb_" + j + "_IASNVS")
						pageNumber++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
				
				}

				

			}

			//Cruise Phase
			let cruisePhaseNum = graphData['Circuit ' + i]['Cruise Phase'].count
			//console.log(graphData['Circuit ' + i]['Cruise Phase'])
			for (let j = 1; j <= cruisePhaseNum; j++) {
              
				//Altitude Profile
				if (graphData['Circuit ' + i]['Cruise Phase'][j]['Altitude Profile'].status === 1) {
					newPage(logo, 
						report, 
						"Cruise Phase "+j, 
						"Altitude Profile",
						165,
						'Circuit ' + i,
						290,
						graphData['Circuit ' + i]['Cruise Phase'][j]['Altitude Profile'].auto_comment, 
						"cr" + i + "_Cruise_" + j + "_Alt")
						pageNumber ++
						if(graphData['Circuit ' + i]['Cruise Phase'][j]['Altitude Profile'].auto_comment !== "Satisfactory"){
							pageNum.push(pageNumber)
						}
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
						
				}

				//Heading Profile
				if (graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].status === 1) {
					newPage(logo, 
						report, 
						"Cruise Phase "+j, 
						"Heading Profile",
						165,
						'Circuit ' + i,
						290,
						graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].auto_comment, 
						"cr" + i + "_Cruise_" + j + "_HDG")
						pageNumber ++
						if(graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].auto_comment !== "Satisfactory"){
							pageNum.push(pageNumber)
						}
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
						
				}

				//IAS and GroundSpeed Profile
				// if (graphData['Circuit ' + i]['Cruise Phase'][j]['IAS and GroundSpeed Profile'].status === 1) {
				// 	report.addPage()
				// 	report.rect(10, 10, report.internal.pageSize.width - 20, report.internal.pageSize.height - 20, "S")
				// 	report.addImage(logo, 'png', 20, 20, 100, 10);
				// 	report.text('Circuit - ' + i, width / 2, 40, { align: 'center' })
				// 	report.text('Cruise Phase' + j + ' : IAS and GroundSpeed Profile', width / 2, 60, { align: 'center' })
				// 	let iasgndspdGraph = document.getElementById("cr" + i + "_Cruise_" + j + "_IASNGS")
				// 	var iasgndspdGraphData = iasgndspdGraph.toDataURL();
				// 	report.addImage(iasgndspdGraphData, 'png', 80, 80, width - 160, height - 160)

				// }
			}





			//Descent Phase
			let descentPhaseNum = graphData['Circuit ' + i]['Descent Phase'].count
			for (let j = 1; j <= descentPhaseNum; j++) {

				//Altitude Profile
				if (graphData['Circuit ' + i]['Descent Phase'][j]['Altitude Profile']) {
					newPage(logo, 
						report, 
						"Descent Phase "+j, 
						"Altitude Profile",
						180,
						'Circuit ' + i,
						290,
						null, 
						"cr" + i + "_Descent_" + j + "_Alt")
						pageNumber ++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
					
				}

				//IAS and Ground Speed Profile
				if (graphData['Circuit ' + i]['Descent Phase'][j]['IAS and GS Profile']) {
					newPage(logo, 
						report, 
						"Descent Phase "+j, 
						"IAS and GS Profile",
						180,
						'Circuit ' + i,
						325,
						null, 
						"cr" + i + "_Descent_" + j + "_IASNGS")
						pageNumber ++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
				
				}

					//Vertical Speed Profile
					if (graphData['Circuit ' + i]['Descent Phase'][j]['Vertical Speed Profile']) {
						newPage(logo, 
							report, 
							"Descent Phase "+j, 
							"Vertical Speed Profile",
							180,
							'Circuit ' + i,
							340,
							null, 
							"cr" + i + "_Descent_" + j + "_VS")
							pageNumber ++
							if(firstGraph === 0){
								firstGraph = report.internal.getCurrentPageInfo().pageNumber
							}
					
					}

			}


			//Approach Phase
			if (graphData['Circuit ' + i]['Approach Phase']['Altitude Profile'].status === 1) {
				newPage(logo, 
					report, 
					"Approach Phase", 
					"Altitude Profile",
					180,
					'Circuit ' + i,
					300,
					null, 
					"cr" + i + "_App_Alt")
					pageNumber ++
					if(firstGraph === 0){
						firstGraph = report.internal.getCurrentPageInfo().pageNumber
					}
			
			}

			if (graphData['Circuit ' + i]['Approach Phase']['IAS and GS Profile'].status === 1) {
				newPage(logo, 
					report, 
					"Approach Phase", 
					"IAS and GS Profile",
					180,
					'Circuit ' + i,
					330,
					null, 
					"cr" + i + "_App_IASNGS")
					pageNumber ++
					if(firstGraph === 0){
						firstGraph = report.internal.getCurrentPageInfo().pageNumber
					}
				
			}
			
			//Landing Phase
			if (graphData['Circuit ' + i]['Landing Phase'].landing_detected === 1) {

				//Pitch Profile
				if (graphData['Circuit ' + i]['Landing Phase']['Pitch Profile'].status == 1) {
					newPage(logo, 
						report, 
						"Landing Phase", 
						"Pitch Profile",
						170,
						'Circuit ' + i,
						270,
						null, 
						"cr" + i + "_Land_Pitch")
						pageNumber ++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}

				
				}

				//Speed Profile
				if (graphData['Circuit ' + i]['Landing Phase']['Speed Profile'].status == 1) {
					newPage(logo, 
						report, 
						"Landing Phase", 
						"Speed Profile",
						170,
						'Circuit ' + i,
						270,
						graphData['Circuit ' + i]['Landing Phase']['Speed Profile'].auto_comment, 
						"cr" + i + "_Land_SPD")
						pageNumber ++
						if(graphData['Circuit ' + i]['Landing Phase']['Speed Profile'].auto_comment !== "Satisfactory"){
							pageNum.push(pageNumber)
						}
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
						
				}

				//Altitude Profile
				if (graphData['Circuit ' + i]['Landing Phase']['Altitude Profile'].status == 1) {
					newPage(logo, 
						report, 
						"Landing Phase", 
						"Altitude Profile",
						170,
						'Circuit ' + i,
						280,
						graphData['Circuit ' + i]['Landing Phase']['Altitude Profile'].auto_comment, 
						"cr" + i + "_Land_Alt")
						pageNumber ++
						if(graphData['Circuit ' + i]['Landing Phase']['Altitude Profile'].auto_comment !== "Satisfactory"){
							pageNum.push(pageNumber)
						}
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
			
				}

				//Flare Profile
				if (graphData['Circuit ' + i]['Landing Phase']['Flare Profile'].status == 1) {
					newPage(logo, 
						report, 
						"Landing Phase", 
						"Flare Profile",
						170,
						'Circuit ' + i,
						270,
						null, 
						"cr" + i + "_Land_Flare")
						pageNumber++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
			
				}

				//Roll Out Profile
				if (graphData['Circuit ' + i]['Landing Phase']['Roll Out'].status == 1) {
					newPage(logo, 
						report, 
						"Landing Phase", 
						"Roll Out",
						170,
						'Circuit ' + i,
						240,
						null, 
						"cr" + i + "_Land_Roll")
						pageNumber++
						if(firstGraph === 0){
							firstGraph = report.internal.getCurrentPageInfo().pageNumber
						}
			
				}
			}


		//}
	}



	//Post Landing Phase
	if (graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].status === 1 && graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].touch_and_go !== 1) {
		newPage(logo, 
			report, 
			"Post Landing Phase", 
			"Taxi Speed: Post Final Landing",
			200,
			null,
			null,
			graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].auto_comment, 
			"lastTaxiGraph")
			pageNumber ++
			if(graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].auto_comment !== "Satisfactory"){
				pageNum.push(pageNumber)
			}
			if(firstGraph === 0){
				firstGraph = report.internal.getCurrentPageInfo().pageNumber
			}
			
	
	}

	remarkPage = pageNumber
	//Remark Summary
	
	let totalRemark = remarkSummary(logo, report, graphData, pageNum, firstGraph-2);

	//add link remark page
	report.setPage(1)
	report.setFont('IBMPlexSans-Bold', 'bold')
	report.setFontSize(14)
	report.setTextColor(69, 118, 246)
	report.textWithLink("Remark Summary ("+totalRemark+")", 685, 552,{pageNumber : 2})
	report.setLineWidth(1); 
	report.setDrawColor(69, 118, 246)
	const textWidth1 = report.getTextWidth("Remark Summary ("+totalRemark+")");
	report.line(685, 553, 685+textWidth1, 553)

	pageNum.forEach(element => {
		report.setPage(element+firstGraph-2)
		report.setFont('IBMPlexSans-Bold', 'bold')
		report.setFontSize(14)
		report.setTextColor(69, 118, 246)
		report.textWithLink("All Remark", 736, 516,{pageNumber : 2})
		report.setLineWidth(1); 
		report.setDrawColor(69, 118, 246)
		const textWidth2 = report.getTextWidth("All Remark"); 
		report.line(736, 517, 736+textWidth2, 517)
	});

	for(let k=2; k<firstGraph; k++){
		report.deletePage(2)
	}
	let lastPage = report.internal.getNumberOfPages(); 
	for(let k=2; k<firstGraph; k++){
		report.movePage(lastPage, 2)
	}
	
	

	let filename = fullFileName.split('.').slice(0, -1).join('.')

	let fileNameToSave = filename + "_aerx_log_analysis_" + day + "_" + month + "_" + year + "_" + hours + "_" + min + ".pdf";

	var pdfFile = report.output('blob')

	AuthService.uploadReportPdf(pdfFile, fileNameToSave).then((recData) => {
		//console.log(recData)
	},
		(error) => {
			console.log(error);
		}
	);
	report.save(fileNameToSave);
	stopSpinnerCallBack();
}

const newPage = (logoImg, doc, phase, profile, pY, circuit, cY,remark,canvasId) => {
		doc.addPage()
		//report.rect(10, 10, report.internal.pageSize.width - 20, report.internal.pageSize.height - 20, "S")
		doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
		doc.setFillColor(216, 216, 216)
		doc.setLineWidth(1); 
		doc.setDrawColor(216, 216, 216)
		doc.line(20, 0, 20, 498)
		doc.setTextColor(0, 0, 0)
		doc.setFontSize(16)
		if(phase !== null){
			doc.setFont('IBMPlexSans-Bold', 'bold')
			doc.text(37, 28, phase+" :")
		}
		if(profile !== null){
			doc.setFont(undefined, 'normal')
			doc.text(pY, 28, profile)
		}
		if(circuit !== null){
			doc.setFillColor(66, 113, 235)
			doc.setFont('IBMPlexSans-Regular', 'normal')
			doc.setFontSize(12)
			doc.roundedRect(cY, 12, 65, 20, 10, 10, 'F')
			doc.setTextColor(255, 255, 255)
			doc.text(cY+8, 26, circuit)
		}
		if(remark !== null){
			doc.setFontSize(14)
			doc.setFont('IBMPlexSans-Regular', 'normal')
			if(remark !== "Satisfactory"){
				doc.setFillColor(255,245,245)
				doc.setDrawColor(245, 225, 225)
				doc.roundedRect(37, 471, 789, 60, 8, 8, 'FD')
				doc.setTextColor(254, 83, 83)
				doc.text(53, 493, '* Remark : '+ remark, { maxWidth: 700 })
				
			}else{
				doc.setFillColor(255,255,255)
				doc.setDrawColor(216, 216, 216)
				doc.roundedRect(37, 471, 789, 60, 8, 8, 'FD')
				doc.setTextColor(0, 0, 0)
				doc.text(53, 493, '* Remark : '+ remark)
			}
		}
		let eleGraph = document.getElementById(canvasId)
		let ctx = eleGraph.getContext("2d");
        ctx.globalCompositeOperation='destination-over';
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, eleGraph.width, eleGraph.height)
		var eleGraphData = eleGraph.toDataURL("image/jpeg", 1)
		doc.addImage(eleGraphData, 'jpeg', 36.5, 56, 789, 367)
}



const remarkSummary = (logoImg, doc, graphData, pageNumber, dummyPageOffsets) =>{
		//remarkSummary
	let	pageNum = [...pageNumber]
	let totalRemarks = 0
	doc.addPage();
	//doc.insertPage(2)
	let newPageOffset = 0

	doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
	doc.setFillColor(216, 216, 216)
	doc.setLineWidth(1); 
	doc.setDrawColor(216, 216, 216)
	doc.line(20, 0, 20, 498)

		//remarkk text
	doc.setTextColor(0, 0, 0)
	doc.setFontSize(16)
	doc.setFont('IBMPlexSans-Bold', 'bold')
	doc.text(37, 28, "Remark Summary")
	newPageOffset += 20

	let cktNum = graphData.Total_circuits
	for (let i = 1; i <= cktNum; i++) {

		if(newPageOffset > 500){
			doc.addPage()
			//doc.insertPage(2)
			doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
			doc.setFillColor(216, 216, 216)
			doc.setLineWidth(1); 
			doc.setDrawColor(216, 216, 216)
			doc.line(20, 0, 20, 498)
			newPageOffset = 20
		}
		//circuit label
		doc.setFillColor(71, 122, 254)
		doc.roundedRect(37, newPageOffset+34, 65, 20, 10, 10, 'F')
		doc.setTextColor(255, 255, 255)
		doc.setFont('IBMPlexSans-Bold', 'bold')
		doc.setFontSize(12)
		doc.text(45, newPageOffset+48, "Circuit "+i)
		newPageOffset += 60

		//Pre T/O Phase
	
		if (graphData['Circuit ' + i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].status === 1 && graphData['Circuit ' + i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].touch_and_go !== 1 && graphData['Circuit '+i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment !== "Satisfactory") {
			if(newPageOffset > 500){
				doc.addPage()
				//doc.insertPage(2)
				doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
				doc.setFillColor(216, 216, 216)
				doc.setLineWidth(1); 
				doc.setDrawColor(216, 216, 216)
				doc.line(20, 0, 20, 498)
				newPageOffset = 20
			}
			doc.setTextColor(0, 0, 0)
			doc.setFontSize(14)
			doc.setFont('IBMPlexSans-Regular', 'normal')
			doc.setFillColor(200, 224, 103)
			//doc.roundedRect(49, 96, 108, 28, 8, 8, 'F')     //80
			doc.roundedRect(49, newPageOffset+16, 108, 28, 8, 8, 'F')
			doc.text(57, newPageOffset+34, "Pre T/O Phase")
			doc.text(49, newPageOffset+67, "- Taxi Speed Profile")
			if(graphData['Circuit '+i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment !== "Satisfactory"){
				doc.setDrawColor(245, 225, 225)
				doc.setFillColor(255, 245, 245)
				doc.setTextColor(254, 83, 83)
			}else{
				doc.setDrawColor(216, 216, 216)
				doc.setFillColor(255, 255, 255)
				doc.setTextColor(0, 0, 0)
			}
			doc.roundedRect(59, newPageOffset+88, 767, 34, 8, 8, 'FD')
			doc.link(59, newPageOffset+88, 767, 34, {pageNumber : pageNum[0]+dummyPageOffsets})
			//doc.text(67, newPageOffset+110, graphData['Circuit '+i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment)
			doc.textWithLink(graphData['Circuit '+i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment, 67, newPageOffset+110, {pageNumber : pageNum[0]+dummyPageOffsets})
			let textWidth = doc.getTextWidth(graphData['Circuit '+i]['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment); 
			doc.setDrawColor(doc.getTextColor())
			doc.line(67, newPageOffset+112, 67+textWidth, newPageOffset+112)
			doc.setTextColor(71, 122, 254)
			doc.text(804, newPageOffset+109,">")
			pageNum.shift()
			newPageOffset += 120
			totalRemarks++
		}
	
		//report.textWithLink(graphData['Circuit 1']['Pre T/O Phase']['Taxi Speed: Pre Takeoff'].auto_comment, 67, 190,  {pageNumber:12})

		//cruise phase
		let cruisePhaseNum = graphData['Circuit ' + i]['Cruise Phase'].count
		for (let j = 1; j <= cruisePhaseNum; j++) {
			if(graphData['Circuit '+i]['Cruise Phase'][j]['Altitude Profile'].auto_comment !== "Satisfactory" || graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].auto_comment !== "Satisfactory"){
				if(newPageOffset > 500){
					doc.addPage()
					//doc.insertPage(2)
					doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
					doc.setFillColor(216, 216, 216)
					doc.setLineWidth(1); 
					doc.setDrawColor(216, 216, 216)
					doc.line(20, 0, 20, 498)
					newPageOffset = 20
				}
				doc.setTextColor(0, 0, 0)
				doc.setFontSize(14)
				doc.setFont('IBMPlexSans-Regular', 'normal')
				doc.setFillColor(200, 224, 103)
				doc.roundedRect(49, newPageOffset+16, 108, 28, 8, 8, 'F')
				doc.text(57, newPageOffset+34, "Cruise Phase "+j)
				newPageOffset += 40

					//altitude profile
		
					if (graphData['Circuit ' + i]['Cruise Phase'][j]['Altitude Profile'].status === 1 && graphData['Circuit '+i]['Cruise Phase'][j]['Altitude Profile'].auto_comment !== "Satisfactory") {
						if(newPageOffset > 500){
							doc.addPage()
							//doc.insertPage(2)
							doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
							doc.setFillColor(216, 216, 216)
							doc.setLineWidth(1); 
							doc.setDrawColor(216, 216, 216)
							doc.line(20, 0, 20, 498)
							newPageOffset = 20
						}
						doc.setTextColor(0, 0, 0)
						doc.text(49, newPageOffset+26, "- Altitude Profile")
						//doc.setFillColor(255, 245, 245)
						if(graphData['Circuit '+i]['Cruise Phase'][j]['Altitude Profile'].auto_comment !== "Satisfactory"){
							doc.setDrawColor(245, 225, 225)
							doc.setFillColor(255, 245, 245)
							doc.setTextColor(254, 83, 83)
						}else{
							doc.setDrawColor(216, 216, 216)
							doc.setFillColor(255, 255, 255)
							doc.setTextColor(0, 0, 0)
						}
						doc.roundedRect(59, newPageOffset+47, 767, 34, 8, 8, 'FD')
						doc.link(59, newPageOffset+47, 767, 34, {pageNumber : pageNum[0]+dummyPageOffsets})
						//doc.setTextColor(254, 83, 83)
						doc.textWithLink(graphData['Circuit '+i]['Cruise Phase'][j]['Altitude Profile'].auto_comment, 67, newPageOffset+69, {pageNumber : pageNum[0]+dummyPageOffsets})
						let textWidth = doc.getTextWidth(graphData['Circuit '+i]['Cruise Phase'][j]['Altitude Profile'].auto_comment); 
						doc.setDrawColor(doc.getTextColor())
						doc.line(67, newPageOffset+72, 67+textWidth, newPageOffset+72)
						doc.setTextColor(71, 122, 254)
						doc.text(804, newPageOffset+68,">")
						pageNum.shift()
						newPageOffset += 80
						totalRemarks++
					
				}

					//heading profile
					if (graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].status === 1 && graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].auto_comment !== "Satisfactory") {
						if(newPageOffset > 500){
							doc.addPage()
							//doc.insertPage(2)
							doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
							doc.setFillColor(216, 216, 216)
							doc.setLineWidth(1); 
							doc.setDrawColor(216, 216, 216)
							doc.line(20, 0, 20, 498)
							newPageOffset = 20
						}
						doc.setTextColor(0, 0, 0)
						doc.text(49, newPageOffset+26, "- Heading Profile")
						if(graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].auto_comment !== "Satisfactory"){
							doc.setDrawColor(245, 225, 225)
							doc.setFillColor(255, 245, 245)
							doc.setTextColor(254, 83, 83)
						}else{
							doc.setDrawColor(216, 216, 216)
							doc.setFillColor(255, 255, 255)
							doc.setTextColor(0, 0, 0)
						}
						//doc.setFillColor(255, 245, 245)
						doc.roundedRect(59, newPageOffset+47, 767, 34, 8, 8, 'FD')
						doc.link(59, newPageOffset+47, 767, 34, {pageNumber : pageNum[0]+dummyPageOffsets})
						//doc.setTextColor(254, 83, 83)
						doc.textWithLink(graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].auto_comment, 67, newPageOffset+69, {pageNumber : pageNum[0]+dummyPageOffsets})
						let textWidth = doc.getTextWidth(graphData['Circuit ' + i]['Cruise Phase'][j]['Heading Profile'].auto_comment);
						doc.setDrawColor(doc.getTextColor()) 
						doc.line(67, newPageOffset+72, 67+textWidth, newPageOffset+72)
						doc.setTextColor(71, 122, 254)
						doc.text(804, newPageOffset+68,">")
						pageNum.shift()
						newPageOffset += 80
						totalRemarks++
					}
				}
			
		}

		//landing phase
		if(graphData['Circuit '+i]['Landing Phase']['Speed Profile'].auto_comment !== "Satisfactory" || graphData['Circuit '+i]['Landing Phase']['Altitude Profile'].auto_comment !== "Satisfactory"){
		if (graphData['Circuit ' + i]['Landing Phase'].landing_detected === 1) {
			if(newPageOffset > 500){
				doc.addPage()
				//doc.insertPage(2)
				doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
				doc.setFillColor(216, 216, 216)
				doc.setLineWidth(1); 
				doc.setDrawColor(216, 216, 216)
				doc.line(20, 0, 20, 498)
				newPageOffset = 20
			}
			doc.setTextColor(0, 0, 0)
			doc.setFontSize(14)
			doc.setFont('IBMPlexSans-Regular', 'normal')
			doc.setFillColor(200, 224, 103)
			doc.roundedRect(49, newPageOffset+16, 108, 28, 8, 8, 'F')
			doc.text(57, newPageOffset+34, "Landing Phase")
			newPageOffset += 40
				//speed profile
			if (graphData['Circuit ' + i]['Landing Phase']['Speed Profile'].status == 1 && graphData['Circuit '+i]['Landing Phase']['Speed Profile'].auto_comment !== "Satisfactory") {
				if(newPageOffset > 500){
					doc.addPage()
					//doc.insertPage(2)
					doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
					doc.setFillColor(216, 216, 216)
					doc.setLineWidth(1); 
					doc.setDrawColor(216, 216, 216)
					doc.line(20, 0, 20, 498)
					newPageOffset = 20
				}
				doc.setTextColor(0, 0, 0)
				doc.text(49, newPageOffset+26, "- Speed Profile")
				//doc.setFillColor(255, 245, 245)
				if(graphData['Circuit '+i]['Landing Phase']['Speed Profile'].auto_comment !== "Satisfactory"){
					doc.setDrawColor(245, 225, 225)
					doc.setFillColor(255, 245, 245)
					doc.setTextColor(254, 83, 83)
				}else{
					doc.setDrawColor(216, 216, 216)
					doc.setFillColor(255, 255, 255)
					doc.setTextColor(0, 0, 0)
				}
				doc.roundedRect(59, newPageOffset+47, 767, 34, 8, 8, 'FD')
				doc.link(59, newPageOffset+47, 767, 34, {pageNumber : pageNum[0]+dummyPageOffsets})
				//doc.setTextColor(254, 83, 83)
				doc.textWithLink(graphData['Circuit '+i]['Landing Phase']['Speed Profile'].auto_comment, 67,  newPageOffset+69, {pageNumber : pageNum[0]+dummyPageOffsets})
				let textWidth = doc.getTextWidth(graphData['Circuit '+i]['Landing Phase']['Speed Profile'].auto_comment);
				doc.setDrawColor(doc.getTextColor())
				doc.line(67, newPageOffset+72, 67+textWidth, newPageOffset+72)
				doc.setTextColor(71, 122, 254)
				doc.text(804, newPageOffset+68,">")
				pageNum.shift()
				newPageOffset += 80
				totalRemarks++
			}
		

				//altitude profile
			if (graphData['Circuit ' + i]['Landing Phase']['Altitude Profile'].status == 1 && graphData['Circuit '+i]['Landing Phase']['Altitude Profile'].auto_comment !== "Satisfactory") {
				if(newPageOffset > 500){
					doc.addPage()
					//doc.insertPage(2)
					doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
					doc.setFillColor(216, 216, 216)
					doc.setLineWidth(1); 
					doc.setDrawColor(216, 216, 216)
					doc.line(20, 0, 20, 498)
					newPageOffset = 20
				}
				doc.setTextColor(0, 0, 0)
				doc.text(49,  newPageOffset+26, "- Altitude Profile")
				//doc.setFillColor(255, 245, 245)
				if(graphData['Circuit '+i]['Landing Phase']['Altitude Profile'].auto_comment !== "Satisfactory"){
					doc.setDrawColor(245, 225, 225)
					doc.setFillColor(255, 245, 245)
					doc.setTextColor(254, 83, 83)
				}else{
					doc.setDrawColor(216, 216, 216)
					doc.setFillColor(255, 255, 255)
					doc.setTextColor(0, 0, 0)
				}
				doc.roundedRect(59,  newPageOffset+47, 767, 34, 8, 8, 'FD')
				doc.link(59, newPageOffset+47, 767, 34, {pageNumber : pageNum[0]+dummyPageOffsets})
				//doc.setTextColor(254, 83, 83)
				doc.textWithLink(graphData['Circuit '+i]['Landing Phase']['Altitude Profile'].auto_comment, 67,  newPageOffset+69, {pageNumber : pageNum[0]+dummyPageOffsets})
				let textWidth = doc.getTextWidth(graphData['Circuit '+i]['Landing Phase']['Altitude Profile'].auto_comment);
				doc.setDrawColor(doc.getTextColor())
				doc.line(67, newPageOffset+72, 67+textWidth, newPageOffset+72)
				doc.setTextColor(71, 122, 254)
				doc.text(804, newPageOffset+68,">")
				pageNum.shift()
				newPageOffset += 80
				totalRemarks++
			}
		}
		}
	}
	
		//post landing
	if (graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].status === 1 && graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].touch_and_go !== 1 && graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].auto_comment !== "Satisfactory") {
		if(newPageOffset > 400){
			doc.addPage()
			//doc.insertPage(2)
			doc.addImage(logoImg, 'png', 25, 575, 87, 12, undefined, 'NONE', 90)
			doc.setFillColor(216, 216, 216)
			doc.setLineWidth(1); 
			doc.setDrawColor(216, 216, 216)
			doc.line(20, 0, 20, 498)
			newPageOffset = 20
		}
		doc.setTextColor(0, 0, 0)
		doc.setFontSize(14)
		doc.setFont('IBMPlexSans-Regular', 'normal')
		doc.setFillColor(200, 224, 103)
		doc.roundedRect(49, newPageOffset+16, 135, 28, 8, 8, 'F')
		doc.text(57, newPageOffset+34, "Post Landing Phase")
		doc.text(49, newPageOffset+67, "- Taxi Speed: Post Final Landing")
		//doc.setFillColor(255, 245, 245)
		if(graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].auto_comment !== "Satisfactory"){
			doc.setDrawColor(245, 225, 225)
			doc.setFillColor(255, 245, 245)
			doc.setTextColor(254, 83, 83)
		}else{
			doc.setDrawColor(216, 216, 216)
			doc.setFillColor(255, 255, 255)
			doc.setTextColor(0, 0, 0)
		}
		doc.roundedRect(59, newPageOffset+88, 767, 34, 8, 8, 'FD')
		doc.link(59, newPageOffset+88, 767, 34, {pageNumber : pageNum[0]+dummyPageOffsets})
		//doc.setTextColor(254, 83, 83)
		doc.textWithLink(graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].auto_comment, 67, newPageOffset+110,{pageNumber : pageNum[0]+dummyPageOffsets})
		doc.setDrawColor(doc.getTextColor())
		let textWidth = doc.getTextWidth(graphData['Post Landing Phase']['Taxi Speed: Post Final Landing'].auto_comment); 
		doc.line(67, newPageOffset+112, 67+textWidth, newPageOffset+112)
		doc.setTextColor(71, 122, 254)
		doc.text(804, newPageOffset+109,">")
		totalRemarks++
	}
	return totalRemarks;
}


export default SavePdf;


export const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;