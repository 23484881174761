// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-graph-wrapper {
    border-radius: 8px;
    background-color: #fff;
    margin: 20px;
    /* padding-bottom: 15px; */
  
  }
  
  .cesium-widget canvas{
    height:520px;
  } `, "",{"version":3,"sources":["webpack://./src/components/Kml/CesiumKml.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,0BAA0B;;EAE5B;;EAEA;IACE,YAAY;EACd","sourcesContent":[".single-graph-wrapper {\n    border-radius: 8px;\n    background-color: #fff;\n    margin: 20px;\n    /* padding-bottom: 15px; */\n  \n  }\n  \n  .cesium-widget canvas{\n    height:520px;\n  } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
