import axios from "axios";
import CryptoJS from "crypto-js";

const API_URL = process.env.REACT_APP_API_LINK;
const AES_KEY = process.env.REACT_APP_AES_KEY;
//const API_URL = "http://192.168.2.39:5000"



const login = (user, pass) => {
    return axios
      .post(API_URL+"/login", {}, {
        auth : {username : user,
          password : pass
      }}
    )
      .then((response) => {
        if (response.data.username) {
          //localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  };




  const generatereport = (data) =>{
    let user = localStorage.getItem("user")
    let pass = localStorage.getItem("pass")
    let decryptPassByte = CryptoJS.AES.decrypt(pass, AES_KEY);
    let decryptPass = decryptPassByte.toString(CryptoJS.enc.Utf8)
    var formData = new FormData();
    formData.append("file", data);
    return axios
      .post(API_URL+"/analyze", formData,{
        auth : {
        username : user,
        password : decryptPass
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    )
      .then((response) => {
       
        return response.data;
      });
  };




  const generatekml = (kmlData) =>{
    let user = localStorage.getItem("user")
    let pass = localStorage.getItem("pass")
    let decryptPassByte = CryptoJS.AES.decrypt(pass, AES_KEY);
    let decryptPass = decryptPassByte.toString(CryptoJS.enc.Utf8)
    return axios
      .post(API_URL+"/kml", kmlData,{
        auth : {
        username : user,
        password : decryptPass
      }}
    )
      .then((response) => {
        //console.log(response)
        return response.data;
      });
  };
  
  const uploadReportPdf = (pdfFie, fileName) =>{
    let user = localStorage.getItem("user")
    let pass = localStorage.getItem("pass")
    let decryptPassByte = CryptoJS.AES.decrypt(pass, AES_KEY);
    let decryptPass = decryptPassByte.toString(CryptoJS.enc.Utf8)
    var formData = new FormData();
    formData.append("file", pdfFie, fileName);
    return axios
      .post(API_URL+"/report", formData,{
        auth : {
        username : user,
        password : decryptPass
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }}
    )
      .then((response) => {
        //console.log(response)
        return response.data;
      });
  }
  
  const logout = () => {
    localStorage.removeItem("user");
    return axios.post(API_URL).then((response) => {
      return response.data;
    });
  };
  
  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  
  const AuthService = {
    login,
    logout,
    getCurrentUser,
    generatereport,
    generatekml,
    uploadReportPdf
  }
  
  export default AuthService;