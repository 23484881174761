import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/auth.service";
import './Login.css'
import { useAuth } from "../context/auth";


function Login(){
    const navigate = useNavigate();
    // if(sessionStorage.getItem("isLoggedIn")){
    //   navigate("/dashboard");
    // }

    const [errorMsg, setErrorMsg] = useState(null)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
   
    const auth = useAuth()

    
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
        setErrorMsg(null)
      };
    
      const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
        setErrorMsg(null)
      };

      const handleLogin = (e) => {
        e.preventDefault();
        if(username === "" || password === ""){
          setErrorMsg("Fill required fields")
          return
        }
        AuthService.login(username, password).then(
          (data) => {
            auth.login(username, password)
            navigate("/dashboard");
            console.log(data)
          },
          (error) => {
            console.log(error);
            setErrorMsg("Please check your credentials")
          }
        );
      };
      useEffect(()=>{
        let a = localStorage.getItem("isLoggedIn")
        if(a !== null || a === false){
          return navigate("/dashboard");
        }
      },[]);
    return(
        <div className="wrapper-Login">
            <div className="container-Login">
              <form onSubmit={handleLogin}>
                    <h3>Welcome  to AerX LogAnalyser</h3>
                    <input type="email" placeholder='Email' className="input-email" name="username" value={username}  onChange={onChangeUsername} required/>
                    <input type="password" placeholder='Password' className="input-pass" name="password" value={password} onChange={onChangePassword} required/>
                    {errorMsg && <div id='erroMsg' style={{color : "red", padding : "10px"}}>{errorMsg}</div>}
                    <button type='submit' className="button-Login" disabled={!username || !password } >Login</button>
              </form> 
            </div>
        </div>
    );
}

export default Login;