import { useState, useEffect } from "react";
import { Line, Scatter, } from "react-chartjs-2";
import { AltColor, AxisBorderColor, AxisGridColor, AxisTickColor, AxisTitleColor,   IASColor, VsColor } from "../../../ColorConst/Graphcolors";

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    Filler,
    TimeSeriesScale,
    TimeScale
} from 'chart.js';
ChartJS.register(
    TimeSeriesScale,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Title,
    Tooltip,
    Filler,
    TimeScale
);


export default function ClimbPhase(props) {

    const [numArray, setNumarray] = useState([]);

    const [SPVPDataArray, setSPVPDataArray] = useState([]);
    const [SPVPStatus, setSPVPStatus] = useState([]);
    //FOR Altitude Profile
    const [AltProfileDataArray, setAltprfArray] = useState([]);
    const [AltProfileStatusArr, setAltProfileStatusArr] = useState([]);
    const [IsPhaseVisible, setIsPhaseVisible] = useState(false);

    const crNO = props.crNO;
    function Plot() {
        let ClimBPhaseData = props.data;
        setIsPhaseVisible(props.data.isVisible ? true : false);
        if (ClimBPhaseData.count > 0) {
            let numberArray = [];

            let AltPrDataArray = [];
            let AltStatusArray = [];

            let SVSDataArray = [];
            let SVSStatusArray = [];

            let count = ClimBPhaseData.count;
            for (let index = 1; index <= count; index++) {

                let CountData = ClimBPhaseData["" + index];
                //Altitude profile
                if (CountData["Altitude Profile"].status === 1) {

                    let AltPrXVal = CountData["Altitude Profile"].x;

                    let dataArray = [];
                
                    for (let index = 0; index < AltPrXVal.length; index++) {

                        let xVal = new Date(AltPrXVal[index]);

                        let tempData = {
                            x: xVal,
                            y: CountData["Altitude Profile"].y[index]
                        }
                        dataArray.push(tempData);

                    }

                    if (CountData["Altitude Profile"].runway !== "0") {

                        let runwaydata = [{ x: new Date(AltPrXVal[0]), y: CountData["Altitude Profile"].y[0] }, { x: new Date(CountData["Altitude Profile"].runway), y: CountData["Altitude Profile"].y[0] }]
                        let tempAltPrData = {
                            datasets: [
                                {
                                    label: 'Altitude',
                                    data: dataArray,
                                    showLine: true,
                                    fill: false,
                                    borderColor: AltColor,
                                    pointRadius: 0.5,

                                },

                                {
                                    label: '',
                                    data: runwaydata,
                                    showLine: true,
                                    backgroundColor: 'rgba(0,0,0,0)',
                                    borderColor: 'rgba(0,0,0,0)',
                                    fill: {
                                        target: 'origin',
                                        above: '#888888',  
                                        below: 'rgb(0, 0, 255)'   
                                    },
                                    pointRadius: 0.5,
                                }
                            ]

                        }
                        AltPrDataArray.push(tempAltPrData);
                        AltStatusArray.push(true);
                    } else {
                        let tempAltPrData = {
                            datasets: [
                                {
                                    label: 'Altitude',
                                    data: dataArray,
                                    showLine: true,
                                    fill: false,
                                    borderColor: AltColor,
                                    pointRadius: 0.5,

                                }
                            ]
                        }
                        AltPrDataArray.push(tempAltPrData);
                        AltStatusArray.push(true);
                    }


                }
                else {
                    AltPrDataArray.push([]);
                    AltStatusArray.push(false);
                }

                if (CountData["IAS and Vs Profile"].status === 1) {
                    let AltPrXVal = CountData["IAS and Vs Profile"].x;
                    let xArray = [];
                    for (let index = 0; index < AltPrXVal.length; index++) {
                        let xVal = AltPrXVal[index].split(" ");
                        xArray.push(xVal[1]);
                    }
                    let TempspdData = {
                        labels: xArray,
                        datasets: [
                            {
                                label: 'IAS',
                                data: CountData["IAS and Vs Profile"].y_1,
                                borderColor: IASColor,

                                borderWidth: 2,
                                yAxisID: 'y',
                                pointRadius: 0.5,
                            },
                            {
                                label: 'VS',
                                data: CountData["IAS and Vs Profile"].y_2,
                                borderColor: VsColor,

                                borderWidth: 2,
                                yAxisID: 'y1',
                                pointRadius: 0.5,
                            },
                        ],
                    };
                    SVSDataArray.push(TempspdData);
                    SVSStatusArray.push(true);
                } else {
                    SVSDataArray.push([]);
                    SVSStatusArray.push(false);
                }

                numberArray.push(index - 1);
            }
            setAltprfArray(AltPrDataArray);

            setAltProfileStatusArr(AltStatusArray);

            setSPVPDataArray(SVSDataArray);
            setSPVPStatus(SVSStatusArray);

            setNumarray(numberArray);
        }
    }
    const AltPrOptions = {
        responsive: true,
        scales: {
            x: {
               
                grid: {
                    color: AxisGridColor,
                    /// drawOnChartArea:false,
                },
                ticks: {
                    color: AxisTickColor, maxTicksLimit: 9,
                    callback: function (val, index) {
                        var d = new Date();
                        d.setTime(val)
                        return d.toLocaleTimeString();
                    },
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Elapsed Time (HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },

            },
            y: {
                grid: {
                    color: AxisGridColor,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                ticks: { color: AxisTickColor },

                title: {
                    display: true,
                    text: "AltMSL (ft)",
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            }
        },
        // maintainAspectRatio: false,
        layout: {
            padding: {
                top: 5,
                left: 5,
                right: 45,
                bottom: 15
            }
        },
        plugins: {
            title: {
                display: false,
                text: 'Custom Chart Title'
            },
            chartAreaBorder: {
                borderColor: 'red',
                borderWidth: 2,
                borderDash: [5, 5],
                borderDashOffset: 2,
            }
        }

    }
    const SPDOptions = {
        stacked: false,
        scales: {
            x: {
                grid: { color: AxisGridColor },
                ticks: { color: AxisTickColor, maxTicksLimit: 9 },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Elapsed Time (HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
            y: {
                display: true,
                position: 'left',
                grid: {
                    color: AxisGridColor,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "IAS (kt)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    color: AxisGridColor,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },

                title: {
                    display: true,
                    text: "Vertical Speed (fpm)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
        },
    }

    useEffect((e) => {
        Plot();
    }, []);
    useEffect(() => {
        setIsPhaseVisible(props.data.isVisible ? true : false);
    }, [props.data.isVisible])
    return (
        <div className={IsPhaseVisible ? '' : 'd-none'}>
            {numArray.map(e => (
                <div >
                    <div >
                        {AltProfileStatusArr[e] &&
                            <div className="single-graph-wrapper" id={`cr${crNO}_Climb_${e + 1}_Altwr`} key={`cr${crNO}_Climb_${e + 1}_Altwr`}>

                                <div className="GraphHeading"  key={`cr${crNO}_Climb_${e + 1}_AltHDG`} >Circuit {crNO} Climb Phase {e+1}: Altitude Profile</div>
                                <Scatter id={`cr${crNO}_Climb_${e + 1}_Alt`} data={AltProfileDataArray[e]} options={AltPrOptions} key={`cr${crNO}_Climb_${e + 1}_Alt`} />

                            </div>
                        }
                        {SPVPStatus[e] &&
                            <div className="single-graph-wrapper" id={`cr${crNO}_Climb_${e + 1}_IASNVSwr`} key={`cr${crNO}_Climb_${e + 1}_IASNVSwr`}>
                                <div className="GraphHeading" key={`cr${crNO}_Climb_${e + 1}_IASNVSHD`} >Circuit {crNO} Climb Phase {e+1}: IAS and VS Profile</div>
                                <Line id={`cr${crNO}_Climb_${e + 1}_IASNVS`} key={`cr${crNO}_Climb_${e + 1}_IASNVS`} data={SPVPDataArray[e]} options={SPDOptions} />

                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}