// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.touchLabel {
    color: "red";
    width: 18px;
    height: 16px;
    margin: 0 0 0 4px;
    font-family: IBMPlexSans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
   
}

.labelText{
    width: 18px;
    height: 16px;
    margin: 0 0 0 4px;
    font-family: IBMPlexSans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/Charts/Phases/LandingPhase.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,wBAAwB;IACxB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;;AAE1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,wBAAwB;IACxB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".touchLabel {\n    color: \"red\";\n    width: 18px;\n    height: 16px;\n    margin: 0 0 0 4px;\n    font-family: IBMPlexSans;\n    font-size: 12px;\n    font-weight: normal;\n    font-stretch: condensed;\n    font-style: normal;\n    line-height: 1.33;\n    letter-spacing: normal;\n   \n}\n\n.labelText{\n    width: 18px;\n    height: 16px;\n    margin: 0 0 0 4px;\n    font-family: IBMPlexSans;\n    font-size: 12px;\n    font-weight: normal;\n    font-stretch: condensed;\n    font-style: normal;\n    line-height: 1.33;\n    letter-spacing: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
