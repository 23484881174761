import './LoginSuccess.css'
import React from 'react';
import * as XLSX from 'xlsx'
import { useState, useRef } from 'react';
import AuthService from '../Services/auth.service';
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate } from 'react-router-dom';
import SampleFileIcon from '../Images/icon-download.svg';
import SampleFiles from '../assets/SampleFiles/LogAnalyzer.zip';
import { useAuth } from '../context/auth';


function LoginSuccess(){
    const auth = useAuth()
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
 //   const [flightReportFile, setFlightReportFile] = useState(null);
    const [flightLogFileData, setFlightLogFileData] = useState(null);
    const [isLogFileDataCorrupt, setIsLogFileDataCorrupt] = useState(false);
    const [flightLogFile, setFlightLogFile] = useState(null);
    const [tailList, setTailList] = useState([]);
    const [tail, setTail] = useState();

    const flightReportRef = useRef();
    const flightLogRef = useRef();
    const [logFilErrMsg, setLogFileErrMsg] = useState("");
    const [reportJsonData, setReportJsonData] = useState({})



    const handleDragOverReport = (event) => {
        event.preventDefault();
    };

    const handleDragOverLog = (event) => {
        event.preventDefault();
    };

    const handleDropReport = (event) => {
        event.preventDefault();
      //  setFlightReportFile(event.dataTransfer.files)
        reportFileChange(event.dataTransfer.files[0].name)
        parserReportFile(event.dataTransfer.files)
    };

    const handleDropLog = (event) => {
        event.preventDefault();
        logFileChange(event.dataTransfer.files[0].name)
        setFlightLogFileData(event.dataTransfer.files);
        setFlightLogFile(event.dataTransfer.files[0].name);
        sessionStorage.setItem('flightLogFile', JSON.stringify(event.dataTransfer.files[0].name));
        // parserLogFile(event.dataTransfer.files);
    };

    const reportFileLoad = (event) => {
        if(event.target.files.length > 0){
          //  setFlightReportFile(event.target.files[0].name)
            reportFileChange(event.target.files[0].name)
            parserReportFile(event.target.files)
        }
    }

    const logFileLoad = (event) => {
        if(event.target.files.length > 0){
            setIsLogFileDataCorrupt(false);
            setLogFileErrMsg('');
            setFlightLogFileData(event.target.files);
            setFlightLogFile(event.target.files[0].name);
            sessionStorage.setItem('flightLogFile', JSON.stringify(event.target.files[0].name));
            logFileChange(event.target.files[0].name);
        }
    }

    const reportFileChange = (filename) =>{
        document.getElementById("reportFileName").innerHTML = filename;
    }

    const logFileChange = (filename) =>{
        document.getElementById("logFileName").innerHTML = filename;
    }

    const parserReportFile = (xlsxFile) => {

        var file = xlsxFile[0]
        let filename = file.name
        const extension = filename.split('.').pop();
        if(extension.toLowerCase() !== "xlsx"){
            setLogFileErrMsg("Unrecognised File Format or Insufficient Data to process!");
            return
        }
        setLogFileErrMsg("");

        const formData = new FormData();
        formData.append('file', file);
        const reader = new FileReader();
        reader.readAsBinaryString(file)
        reader.onload = function (event) {
            var bString = event.target.result;
            //const bstr = file;
            const wBook = XLSX.read(bString, {type:'binary', cellText:true,cellDates:true});
            /* Get first worksheet */
            const wsname = wBook.SheetNames[0];
            const wSheet = wBook.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(wSheet, {header:1, raw:false, dateNF:true});
            //console.log(data)
            /* Update state */
            var headersArray = []
            for(let ele = 0; ele < data[0].length; ele++){
                headersArray.push(data[0][ele]);
            }
            if(!isReportFileValid){
                console.log("report file is not valid")
                return
            }
            setReportJsonData(data);
             var tailarray = []
             for(let row = 2; row<data.length; row++){
                 if(!tailarray.includes(data[row][4]) && data[row][4] !== undefined){
                     tailarray.push(data[row][4])
                 }
             }
             setTailList(tailarray);
        }

    }

    
    const isReportFileValid = (headersArray) =>{
        if(headersArray.includes("Date") && headersArray.includes("eGCA")){
            return true
        }else{
            return false
        }
    }

    const generateClick = () => {
        setIsLoading(true);
        const logFileCsv = flightLogFileData;
        parserLogFile(logFileCsv);
    }
        
    
    const parserLogFile = (csvFile) => {
        let fileObj = csvFile[0]
        let allKeys = [];
       
        if(csvFile.length > 0){
            let file = csvFile[0]
            let filename = file.name
            const extension = filename.split('.').pop();
            if(extension.toLowerCase() !== "csv"){
                console.log(extension.toLowerCase)
                console.log("file not supported");
                setLogFileErrMsg("Unrecognised File Format or Insufficient Data to process!");
                setIsLoading(false)
                return
            }

            let reader = new FileReader();
            reader.readAsText(file);

            reader.onload = function (event) {
                let csvdata = event.target.result;
                let rowsArray = csvdata.split('\n');
                let headers = rowsArray[2].split(',');
                if(!isLogFileValid(headers)){
                    setLogFileErrMsg("Unrecognised File Format or Insufficient Data to process!");
                    setIsLoading(false);
                    setIsLogFileDataCorrupt(true);
                    return
                }
                setIsLogFileDataCorrupt(false);
                setLogFileErrMsg('')

                for(let i=0; i < headers.length; i++){
                    allKeys.push(headers[i]);
                }
                sessionStorage.removeItem("kmlLink")
                getRepoertinfo(file.lastModified);
                
                sessionStorage.setItem("graphData", "");
                sessionStorage.setItem("logFileModified", "")
                AuthService.generatereport(fileObj).then((recData)=>{
                    if(recData?.result?.error_message) {
                        setIsLoading(false);
                        setIsLogFileDataCorrupt(true);
                        setLogFileErrMsg("Unrecognised File Format or Insufficient Data to process!");
                        return;
                    }
                    setIsLogFileDataCorrupt(false);
                    setIsLoading(false);
                    setLogFileErrMsg("");
                   // setIsDataAvailable(true);
                   // setData(recData);
                    sessionStorage.setItem("graphData", JSON.stringify(recData));
                    sessionStorage.setItem("logFileModified", file.lastModifiedDate)
                    navigate("/log-analysis", {replace : true});
                }, 
                    (error) => {
                        console.log(error);
                        setIsLoading(false);
                        setLogFileErrMsg("Something went wrong! Refresh page, try again")
                        if(error?.response?.status===401){
                            auth.logout();
                            navigate("/", {replace:true})
                        }
                      }
                );
            }
        }
    }

    const onSampleFileDownloadClick = () => {

    }

    const isLogFileValid = (headersArray) => {
        let tempArr = [];
        headersArray.forEach(key => {
            tempArr.push(key.trim());
        });

        if (tempArr.includes("IAS") && tempArr.includes("VSpd")) {
            return true;
        } else {
            return false;
        }
    }
  
    const tailChangeHandle = () =>{
        var newtail = document.getElementById("taillists").value;
        setTail(newtail);
    }

    const getRepoertinfo = (dateTime) =>{
        sessionStorage.removeItem("flightInfo")
        var d = new Date(dateTime);
        var hours = d.getHours();
        var minutes = d.getMinutes();
         hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;

        const dateT = new Date();
        dateT.setHours(hours)
        dateT.setMinutes(minutes)

       const MS_PER_MINUTE = 60000;
        const dateRange1 = new Date(dateT - (15 * MS_PER_MINUTE));
        const dateRange2 = new Date(dateT.getTime() + (15 * MS_PER_MINUTE));
      
        let data = reportJsonData;
        for(let row = 1 ;row < data.length; row++){
            if(data[row].includes(tail)){
                const time = data[row][13].split(":")
                const newdate = new Date()
                newdate.setHours(time[0])
                newdate.setMinutes(time[1])

                        if(newdate.getTime() > dateRange1.getTime()){
                            if(newdate.getTime() < dateRange2.getTime()){
                        
                            //   setFlightInfo(data[row]);
                               sessionStorage.setItem('flightInfo', JSON.stringify(data[row]));
                            }
                        }  

                }     
        }
    }


    return(
        <>
        {isLoading &&  <div className='spinnerWrap'><LoadingSpinner /></div> }
    <div className='contents-top-wrap'>
        <div className="wrapper">
            <h2>Upload Log Files</h2>
            <div className="sec-heading first-sec">
                <div className='first-sec-first-part'>
                    <span className='head-number'>1</span>
                    <span className="sec-head-name">Daily Flight Report</span>
                </div>
                <div className='sample-file-section' onClick={onSampleFileDownloadClick}>
                    <a href={SampleFiles} download >
                     <img className='sample-file-icon' src={SampleFileIcon} alt="Sample file"/>
                      Sample File
                    </a>
                </div>
            </div>
            <label htmlFor="flightReport"  onDragOver={handleDragOverReport} onDrop={handleDropReport} className='file-label dropzone ' id='flightReportContainer'>
                <div className="upload-block-wrap">
                    <p id="reportFileName">Click to add file, max file size 200mb</p>
                    {/* <p style={{color:"grey"}}>Limit 200 MB per file</p> */}
                    <input type="file" id="flightReport" name="flightReport" onChange={reportFileLoad} ref={flightReportRef} multiple={false} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                    {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> */}
                </div>
            </label>

            <div className="sec-heading second-sec">
                <span className='head-number'>2</span>
                <span className='sec-head-name'>Aircraft Log File</span>
            </div>
            <div className="selectList">
                <select id="taillists" onChange={tailChangeHandle}>
                    <option defaultValue={this}>Select an aircraft</option>
                    {tailList.map(tail=>
                    <option value={tail} key={tail}>{tail}</option>)}
                </select>
            </div>

            <label htmlFor="flightLog" onDragOver={handleDragOverLog} onDrop={handleDropLog}  id='flightLogContainer'
            className={"file-label " + (isLogFileDataCorrupt ? 'corrupt-file' : '')}>
                <div className="upload-block-wrap">
                    <p id="logFileName">Click to add file, max file size 200mb</p>
                    {/* <p style={{color:"grey"}}>Limit 200 MB per file</p> */}
                    <input type="file" id="flightLog" name="flightLog" onChange={logFileLoad} ref={flightLogRef} multiple={false} accept=".csv"/>
                </div>
            </label>
            {logFilErrMsg !=='' && <p className='errMsg'>{logFilErrMsg}</p>}

            <div className='generate-btn-wrap'>
                <button className='generate-btn' disabled={!flightLogFile || isLoading } onClick={generateClick}>Generate Report</button>
            </div>
        </div>
    </div>
</>
    );
}

export default LoginSuccess;