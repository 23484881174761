import { useState, useEffect } from "react";
import { Line,  } from "react-chartjs-2";
import { AltColor, AxisBorderColor, AxisGridColor, AxisTickColor, GSspeedColor, 
    IASColor, AxisTitleColor, } from "../../../ColorConst/Graphcolors";

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    Filler,
    
} from 'chart.js';
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Title,
    Tooltip,
    Filler
);


export default function ApproaChPhase(props) {
    const [AltiProifileData, setAltiProifileData] = useState();
    const [AltiProifileStatus, setAltiProifileStatus] = useState(false);

    const [IASGSData, setIASGSData] = useState();
    const [IASGSStatus, setIASGSStatus] = useState();
    const [IsPhaseVisible, setIsPhaseVisible] = useState(false);
    const crNO = props.crNO;
    function Plot() {
        let ApproachData = props.data;
        setIsPhaseVisible(props.data.isVisible ? true : false);
        if (ApproachData.approach_detected === 1) {
            if (ApproachData["Altitude Profile"].status === 1) {
                let AltPrXVal = ApproachData["Altitude Profile"].x;
                let xArray = [];
                for (let index = 0; index < AltPrXVal.length; index++) {
                    let xVal = AltPrXVal[index].split(" ");
                    xArray.push(xVal[1]);

                }

                let tempAltPrData = {
                    labels: xArray,
                    datasets: [{
                        label: 'Altitude',
                        data: ApproachData["Altitude Profile"].y,
                        borderColor: AltColor,
                        ///  panGaps: true,
                        borderWidth: 2,
                        pointRadius: 0.4,
                    },
                    ]
                }


                setAltiProifileData(tempAltPrData);
                setAltiProifileStatus(true);
            }

            if (ApproachData["IAS and GS Profile"].status === 1) {
                let AltPrXVal = ApproachData["IAS and GS Profile"].x;
                let xArray = [];
                for (let index = 0; index < AltPrXVal.length; index++) {
                    let xVal = AltPrXVal[index].split(" ");
                    xArray.push(xVal[1]);
                }
                let TempspdData = {
                    labels: xArray,
                    datasets: [
                        {
                            label: 'IAS',
                            data: ApproachData["IAS and GS Profile"].y_1,
                            borderColor: IASColor,
                          
                            borderWidth: 2,
                            yAxisID: 'y',
                            pointRadius: 0.5,
                        },
                        {
                            label: 'GS',
                            data: ApproachData["IAS and GS Profile"].y_2,
                            borderColor: GSspeedColor,
                          
                            borderWidth: 2,
                            yAxisID: 'y1',
                            pointRadius: 0.5,
                        },
                    ],
                };
                setIASGSStatus(true);
                setIASGSData(TempspdData)

            }

        }
    }

    const AltPrOptions = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    color: AxisGridColor,
                    /// drawOnChartArea:false,
                },
                ticks: {
                    color: AxisTickColor, maxTicksLimit: 9,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Elapsed Time (HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },

            },
            y: {
                grid: {
                    color: AxisGridColor,
                    //  drawOnChartArea:false,

                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                ticks: { color: AxisTickColor },

                title: {
                    display: true,
                    text: "AltMSL (ft)",
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            }
        },
        // maintainAspectRatio: false,
        layout: {
            padding: {
                top: 5,
                left: 5,
                right: 45,
                bottom: 15
            }
        },
       

    }
    const IASGSOptions = {
        stacked: false,
        scales: {
            x: {
                grid: { color: AxisGridColor, },
                ticks: { color: AxisTickColor, maxTicksLimit: 9 },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Elapsed Time (MM:HH:SS)",
                    fontSize: 100,
                    color:AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
            y: {
                display: true,
                position: 'left',
                grid: {
                    color: AxisGridColor,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "IAS (kt)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    color: AxisGridColor
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },

                title: {
                    display: true,
                    text: "Ground Speed (kt)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
        },
    }
    useEffect((e) => {
        Plot();
    }, []);
    useEffect(()=>{
        setIsPhaseVisible(props.data.isVisible ? true : false);
    },[props.data.isVisible])
    return (
        <div className={IsPhaseVisible ? '' : 'd-none'}>

            {AltiProifileStatus &&
                <div className="single-graph-wrapper" id={`cr${crNO}_App_Altwr`} key={`cr${crNO}_App_Altwr`}  >
                    <div className="GraphHeading" key={`cr${crNO}_App_AltHDG`} >Circuit {crNO} Approach phase: Altitude profile</div>
                    <Line id={`cr${crNO}_App_Alt`} key={`cr${crNO}_App_Alt`} data={AltiProifileData} options={AltPrOptions} />

                </div>
            }
            {IASGSStatus &&
                <div className="single-graph-wrapper" id={`cr${crNO}_App_IASNGSwr`} key={`cr${crNO}_App_IASNGSwr`}>
                    <div className="GraphHeading" key={`cr${crNO}_App_IASNGSHDG`} >Circuit {crNO} Approach phase: IAS And GS profile</div>
                    <Line id={`cr${crNO}_App_IASNGS`} key={`cr${crNO}_App_IASNGS`} data={IASGSData} options={IASGSOptions} />

                </div>
            }


        </div>
    )
}