import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./context/auth";

// function Protected({isSignedIn, children}){
//     if(!isSignedIn){
//         return(<Navigate to="/" replace />
//             )
//     }
//     return children;
// }

function Protected({ children}){
    const auth = useAuth();
    // if(!auth.user){
    //     return(<Navigate to="/" replace />
    //         )
    // }

    if(!localStorage.getItem("isLoggedIn")){
         return(<Navigate to="/" replace />
             )
     }
    return children;
}



export default Protected;