import './Report.css';
import { useEffect, useState } from 'react';
import AuthService from '../Services/auth.service';
import LoadingSpinner from './LoadingSpinner';
import BackBtn from '../Images/icon-back (1).svg';
import TimeIcon from '../Images/icon-duration.svg';
import FlightDeparture from '../Images/icon-departure.svg';
import FlightArrival from '../Images/icon-arrival.svg';
import FlightLanding from "../Images/icon-landings.svg"
import UnknownLandIng from "../Images/unkonw_op.svg"
import { useNavigate } from 'react-router-dom';
import GraphsComponent from './Charts/GraphParent';
import SavePdf from '../helper/savePdf';
import CesiumKml from './Kml/CesiumKml';

const dayjs = require('dayjs');

function Report(prop) {
    const [isFlightInfoAvailable, setIsFlightInfoAvailable] = useState(false);
    const flightInfoUnavailabeWarning = 'Daily Flight Report file needs to be uploaded for viewing this info.';
    const navigate = useNavigate();
    const [printInfo, setPrintInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isActiveTab, setIsActiveTab] = useState("graph")
    const [kmlLink, setKmlLink] = useState("");
    const [landing_GArr, setLanding_GArr] = useState([]);
    const [unkonwnOpFlag, setunkonwnOpFlag] = useState(false)
    const [loaderFlag, setLoaderFlag] = useState(true)
    //const pdfRef = useRef(null);
    const graphData = prop.rData.result;
    const flightinfo = prop.flightinfo;

    const onBackBtnClick = (e) => {
        navigate("/dashboard");
    };




    function updateInfo() {
        let printInfoArr = []
        if (flightinfo && flightinfo.length > 0) {
            setIsFlightInfoAvailable(true);
            printInfoArr[0] = flightinfo[6];
            printInfoArr[1] = dayjs(flightinfo[2]).format('D MMMM YYYY');      //depature time
            printInfoArr[2] = flightinfo[12].split(':')[0] + ':' + flightinfo[12].split(':')[1] + ', ' + printInfoArr[1];
            printInfoArr[3] = flightinfo[13].split(':')[0] + ':' + flightinfo[13].split(':')[1] + ', ' + printInfoArr[1];
            printInfoArr[4] = flightinfo[14].split(":")[0] + " hr " + flightinfo[14].split(":")[1] + " min ";    //flight duration
            printInfoArr[5] = flightinfo[11]   //excerize
            //printInfoArr[6] = flightinfo[15].toString()   //landing
        }
        else {

            printInfoArr[0] = ""
            printInfoArr[1] = ""
            printInfoArr[2] = ""
            printInfoArr[3] = ""
            printInfoArr[4] = ""
            printInfoArr[5] = ""


        }
        let circuitNo = graphData['Total_circuits'];
        let CircuitGArray = [];


        for (let index = 1; index <= circuitNo; index++) {
            let g = graphData["Circuit " + index]["Landing Phase"].landing_g;
            let keyVal = { crNo: index }
            if (g < 1) keyVal["gValue"] = "~1";
            else keyVal["gValue"] = g;
            CircuitGArray.push(keyVal);
        }
        if (graphData["Unknown_operation"] === 1) setunkonwnOpFlag(true);

        setLanding_GArr(CircuitGArray);
        printInfoArr[6] = circuitNo;
        setPrintInfo(printInfoArr)
    }
    //var printInfo = []
    useEffect(() => {
        updateInfo();
    }, [])



    const CallSavePdf = () => {
        setIsLoading(true)

        setTimeout(() => {
            SavePdf(graphData, printInfo, prop.fileName, stopSpinner)
            //setIsLoading(false)
        }, 50);
    }



    const downloadkml = () => {
        let kmlLink = sessionStorage.getItem("kmlLink")
        if (kmlLink === null) {
            setTimeout(() => {
                setIsLoading(true)
            }, 0)
            let kmlData = graphData.data_kml
            let d = sessionStorage.getItem("logFileModified")
            let date = d.split(" ")
            let pilot = ""
            if (sessionStorage.getItem("flightInfo") != null) {
                let tempfligtInfo = JSON.parse(sessionStorage.getItem("flightInfo"))
                pilot = tempfligtInfo[6] + "_"
            }
            //console.log(date[2]+"_"+date[1]+"_"+date[3]+"_"+date[4])
            kmlData[0]['time_stamp'] = pilot + date[2] + "_" + date[1] + "_" + date[3] + "_" + date[4]
            AuthService.generatekml(kmlData).then((recData) => {
                setIsLoading(false)
                sessionStorage.setItem("kmlLink", recData.kml_link)
                setKmlLink(recData.kml_link)
                window.open(recData.kml_link);
            },
                (error) => {
                    setIsLoading(false)
                    console.log(error);
                }
            );
        } else {
            window.open(kmlLink);
        }

    }

    const activeGraph = () => {
        setIsActiveTab("graph")
    }


    const activeMap = () => {
        // let kmlLink = sessionStorage.getItem("kmlLink")
        // if(kmlLink === null){
        //     //genrateKml();
        // }
        //
        // if (loaderFlag) {
        //     setIsLoading(loaderFlag)
        //     setLoaderFlag(false)
        // }
        setIsActiveTab("map");
    }

    const stopSpinner = () => {
        setIsLoading(false)
    }

    useEffect(() => {
        let kml = sessionStorage.getItem("kmlLink")
        if (kml !== null) {
            setKmlLink(kml)
        }
    }, [isActiveTab])

    if (graphData['Flight Analysis'] === 1) {
        return (
            <div className='RWrapper'>
                {isLoading && <div className='spinnerWrap'><LoadingSpinner /></div>}
                <div className='left-section'>
                    <div className='left-top-part'>
                        <div className="left-top-header">
                            <img title="Back" src={BackBtn} alt='Back button' className='back-btn-img' onClick={onBackBtnClick}></img>
                            <span><h4>{printInfo[1] ? printInfo[1] + ',' : ''} Log Analysis Report</h4></span>
                        </div>
                        <div className='FlightDetailsWrapper'>
                            <div>
                                <div className='mb-1'>
                                    <label className='label-name'>Pilot's Name: </label>
                                    {isFlightInfoAvailable ? <span>{printInfo[0]}</span>
                                        : <span className='warning-icon ml-2'
                                            title={flightInfoUnavailabeWarning}></span>}
                                </div>
                                <div>
                                    <label className='label-name'>Exercise    : </label>
                                    {isFlightInfoAvailable ? <span>{printInfo[5]}</span>
                                        : <span className='warning-icon ml-2'
                                            title={flightInfoUnavailabeWarning}></span>}
                                </div>
                            </div>
                            {unkonwnOpFlag && <div className='blue-box-large' style={{ backgroundColor: "#fff5f5" }}>
                                <div className='blue-box-row'>
                                    <div className='label-cont'>
                                        <img className='mr-1' src={UnknownLandIng} alt="" />
                                        <label className='label-name'>Unknown Operations</label>
                                    </div>
                                </div>
                                <span className='block' style={{ marginLeft: "20px" }}>Please be advised that certain data processing has been interrupted due to incidents such as crash landings or landing not on runways.</span>
                            </div>
                            }
                            <div className='blue-box-container-1'>
                                <div className='blue-box w-45'>
                                    <div className='icon-margin'><img src={TimeIcon} alt="" /></div>
                                    <div>
                                        <label className='label-name'>Flight duration</label>
                                        {isFlightInfoAvailable ? <span className='block'>{printInfo[4]}</span>
                                            : <span className='warning-icon block mt-2'
                                                title={flightInfoUnavailabeWarning}></span>}
                                    </div>
                                </div>
                                <div className='blue-box w-45'>
                                    <div className='icon-margin'><img src={TimeIcon} alt="" /></div>
                                    <div>
                                        <label className='label-name'>Landings</label>
                                        <span className='block'>{printInfo[6]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='blue-box-large'>
                                <div className='blue-box-row'>
                                    <div className='label-cont'>
                                        <img className='mr-1' src={FlightDeparture} alt="" />
                                        <label className='label-name'>Departure Time</label>
                                    </div>
                                    {isFlightInfoAvailable ? <span>{printInfo[2]}</span>
                                        : <span className='warning-icon block'
                                            title={flightInfoUnavailabeWarning}></span>}
                                </div>
                                <div className='blue-box-row mt-20'>
                                    <div className='label-cont'>
                                        <img className='mr-1' src={FlightArrival} alt="" />
                                        <label className='label-name'>Arrival Time</label>
                                    </div>
                                    {isFlightInfoAvailable ? <span>{printInfo[3]}</span>
                                        : <span className='warning-icon block'
                                            title={flightInfoUnavailabeWarning}></span>}
                                </div>
                            </div>

                            <div className='blue-box-large'>
                                <div className='blue-box-row'>
                                    <div className='label-cont'>
                                        <img className='mr-1' src={FlightLanding} alt="" />
                                        <label className='label-name'>Touchdowns</label>
                                    </div>

                                </div>

                                {landing_GArr.map(e => (
                                    <span className='block' style={{ marginLeft: "20px" }} key={`crg${e["crNO"]}WR`}>Circuit {e["crNo"]} : <span className='-G' key={`crg${e["crNO"]}`}>{e["gValue"]}</span> G</span>
                                ))}
                            </div>
                        </div>

                    </div>

                    <div className='button-section'>
                        <button className="print-report-btn" disabled={isLoading} onClick={CallSavePdf}>Download Report</button>
                        <button className="generate-report-btn" disabled={isLoading} onClick={downloadkml}>Generate KML File</button>
                    </div>
                </div>
                <div className='right-section'>
                    <div className='graph-map-tabs-wrap'>
                        <button className={"button-tab " + (isActiveTab === "graph" ? "button-tab-active" : "")} onClick={activeGraph}>Graphs</button>
                        <button className={"button-tab " + (isActiveTab === "map" ? "button-tab-active" : "")} onClick={activeMap}>3d View</button>
                    </div>
                    <div className={isActiveTab === "graph" ? "" : "hide-div"}>
                        <GraphsComponent result={graphData} />
                    </div>
                    <div className={"kmlMap" + (isActiveTab === "map" ? "" : " hide-div")}>
                        <CesiumKml kmlLink={kmlLink} graphData={graphData} loadSpinner={stopSpinner} />
                    </div>

                    {/* <CesiumKml /> */}
                </div>
            </div>
        );
    } else {
        return (
            <div className='errMsg'>Something went wrong, referesh page</div>
        );
    }

}

export default Report;