import { useState, useEffect } from "react";
import { Line} from "react-chartjs-2";
import { AltColor, AxisBorderColor, AxisGridColor, AxisTickColor, AxisTitleColor, GSspeedColor, IASColor, VsColor } from "../../../ColorConst/Graphcolors";

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    Filler,
} from 'chart.js';
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Title,
    Tooltip,
    Filler
);


export default function DescentPhase(props) {

    const [numArray, setNumarray] = useState([]);

    const [IASGSDataArray, setIASGSDataArray] = useState();
    const [IASGSStatusArr, setIASGSStatusArr] = useState([]);
    //FOR Altitude Profile
    const [AltProfileDataArray, setAltprfArray] = useState();
    const [AltProfileStatusArr, setAltProfileStatusArr] = useState([]);

    //For Vertical Profile
    const [VSprofiledataArr, setVSprofiledataArr] = useState();
    const [VSStatusArr, setVSStatusArr] = useState([]);
    const [IsPhaseVisible, setIsPhaseVisible] = useState(false);
    //For 

    const crNO=props.crNO;

    function Plot() {
        let DecentPhase = props.data;
        setIsPhaseVisible(props.data.isVisible ? true : false);
        if (DecentPhase.count > 0) {
            let numberArray = [];

            let AltPrDataArray = [];
            let AltStatusArray = [];

            let SVSDataArray = [];
            let SVSStatusArray = [];

            let VSDataArr = [];
            let VSdataStatusArr = [];

            let count = DecentPhase.count;
            for (let index = 1; index <= count; index++) {
                let CountData = DecentPhase["" + index];
                //Altitude profile
                if (CountData["Altitude Profile"].status === 1) {

                    let AltPrXVal = CountData["Altitude Profile"].x;
                    let xArray = [];
                    for (let index = 0; index < AltPrXVal.length; index++) {
                        let xVal = AltPrXVal[index].split(" ");
                        xArray.push(xVal[1]);

                    }

                    let tempAltPrData = {
                        labels: xArray,
                        datasets: [{
                            label: 'Altitude',
                            data: CountData["Altitude Profile"].y,
                            borderColor: AltColor,
                            ///  panGaps: true,
                            borderWidth: 2.5,
                            pointRadius: 0,
                        },
                        ]
                    }
                    AltPrDataArray.push(tempAltPrData);
                    AltStatusArray.push(true);

                }
                else {
                    AltPrDataArray.push([]);
                    AltStatusArray.push(false);
                }

                if (CountData["IAS and GS Profile"].status === 1) {
                    let AltPrXVal = CountData["IAS and GS Profile"].x;
                    let xArray = [];
                    for (let index = 0; index < AltPrXVal.length; index++) {
                        let xVal = AltPrXVal[index].split(" ");
                        xArray.push(xVal[1]);
                    }
                    let TempspdData = {
                        labels: xArray,
                        datasets: [
                            {
                                label: 'IAS',
                                data: CountData["IAS and GS Profile"].y_1,
                                borderColor: IASColor,
                                borderWidth: 2,
                                yAxisID: 'y',
                                pointRadius: 0.4,
                            },
                            {
                                label: 'GS',
                                data: CountData["IAS and GS Profile"].y_2,
                                borderColor: GSspeedColor,
                               borderWidth: 2,
                                yAxisID: 'y1',
                                pointRadius: 0.4,
                            },
                        ],
                    };
                    SVSDataArray.push(TempspdData);
                    SVSStatusArray.push(true);
                } else {
                    SVSDataArray.push([]);
                    SVSStatusArray.push(false);
                }

                if (CountData["Vertical Speed Profile"].status === 1) {

                    let AltPrXVal = CountData["Vertical Speed Profile"].x;
                    let xArray = [];
                    for (let index = 0; index < AltPrXVal.length; index++) {
                        let xVal = AltPrXVal[index].split(" ");
                        xArray.push(xVal[1]);

                    }

                    let tempAltPrData = {
                        labels: xArray,
                        datasets: [{
                            label: 'Vertical Speed',
                            data: CountData["Vertical Speed Profile"].y,
                            borderColor: VsColor,
                            ///  panGaps: true,
                            borderWidth: 2,
                            pointRadius: 0.4,
                        },
                        ]
                    }
                    VSDataArr.push(tempAltPrData);
                    VSdataStatusArr.push(true);

                }
                else {
                    VSDataArr.push([]);
                    VSdataStatusArr.push(false);
                }
                numberArray.push(index - 1);
            }
            setAltprfArray(AltPrDataArray);
            setAltProfileStatusArr(AltStatusArray);

            setIASGSDataArray(SVSDataArray);
            setIASGSStatusArr(SVSStatusArray);

            setVSprofiledataArr(VSDataArr);
            setVSStatusArr(VSdataStatusArr);

            setNumarray(numberArray);
        }
    }
    const AltPrOptions = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    color: AxisGridColor,
                    /// drawOnChartArea:false,
                },
                ticks: {
                    color: AxisTickColor, maxTicksLimit: 9,
                },
                border: {
                    color:AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Elapsed Time (HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },

            },
            y: {
                grid: {
                    color: AxisGridColor,
                    //  drawOnChartArea:false,

                },
                border: {
                    color:AxisBorderColor,
                    height: "2px"
                },
                ticks: { color: AxisTickColor },

                title: {
                    display: true,
                    text: "AltMSL (ft) ",
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            }
        },
        // maintainAspectRatio: false,
        layout: {
            padding: {
                top: 5,
                left: 5,
                right: 45,
                bottom: 15
            }
        },
        plugins: {
            title: {
                display: false,
                text: 'Custom Chart Title'
            },
            chartAreaBorder: {
                borderColor: 'red',
                borderWidth: 2,
                borderDash: [5, 5],
                borderDashOffset: 2,
            }
        }

    }
    const IASGSOptions = {
        stacked: false,
        scales: {
            x: {
                grid: { color: AxisGridColor, },
                ticks: { color:AxisTickColor, maxTicksLimit: 9 },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Elapsed Time (HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
            y: {
                display: true,
                position: 'left',
                grid: {
                    color: AxisGridColor,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "IAS (kt)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    color: AxisGridColor,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },

                title: {
                    display: true,
                    text: "Ground Speed (kt)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            },
        },
    }

    const VSoptions = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    color: AxisGridColor,
                    /// drawOnChartArea:false,
                },
                ticks: {
                    color: AxisTickColor, maxTicksLimit: 9,
                },
                border: {
                    color: AxisBorderColor ,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Elapsed Time (HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },

            },
            y: {
                grid: {
                    color: AxisGridColor,
                    //  drawOnChartArea:false,

                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                ticks: { color: AxisTickColor },

                title: {
                    display: true,
                    text: "Vertical Speed (fpm)",
                    color:AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            }
        },
        // maintainAspectRatio: false,
        layout: {
            padding: {
                top: 5,
                left: 5,
                right: 45,
                bottom: 15
            }
        },
        plugins: {
            title: {
                display: false,
                text: 'Custom Chart Title'
            },
            chartAreaBorder: {
                borderColor: 'red',
                borderWidth: 2,
                borderDash: [5, 5],
                borderDashOffset: 2,
            }
        }

    }
    useEffect((e) => {
        Plot();
    }, []);
    useEffect(()=>{
        setIsPhaseVisible(props.data.isVisible ? true : false);
      },[props.data.isVisible])
    return (
        <div className={IsPhaseVisible ? '' : 'd-none'}>
            {numArray.map(e => (
                <div >
                    <div >
                        {AltProfileStatusArr[e] &&
                            <div className="single-graph-wrapper" id={`cr${crNO}_Descent_${e+1}_Altwr`}>
                                <div className="GraphHeading" key={`cr${crNO}_Descent_${e+1}_AltHDG`}>Circuit {crNO} Descent Phase {e+1}: Altitude Profile</div>
                                <Line id={`cr${crNO}_Descent_${e+1}_Alt`} data={AltProfileDataArray[e]} options={AltPrOptions} />

                            </div>
                        }
                        {IASGSStatusArr[e] &&
                            <div className="single-graph-wrapper" id={`cr${crNO}_Descent_${e+1}_IASNGSwr`}>
                                <div className="GraphHeading" key={`cr${crNO}_Descent_${e+1}_IASNGSHDG`}>Circuit {crNO} Descent Phase {e+1}: IAS and GS Profile</div>
                                <Line id={`cr${crNO}_Descent_${e+1}_IASNGS`} data={IASGSDataArray[e]} options={IASGSOptions} />

                            </div>
                        }
                         {VSStatusArr[e] &&
                            <div className="single-graph-wrapper" id={`cr${crNO}_Descent_${e+1}_VSwr`}>
                                <div className="GraphHeading" key={`cr${crNO}_Descent_${e+1}_VSHDG`} >Circuit {crNO} Descent Phase {e+1}: Vertical Speed Profile</div>
                                <Line id={`cr${crNO}_Descent_${e+1}_VS`} data={VSprofiledataArr[e]} options={VSoptions} />

                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}