import { useState, useContext ,createContext } from "react";
import { redirect } from "react-router-dom";
import Login from "../components/Login";
import CryptoJS from "crypto-js";

const AES_KEY = process.env.REACT_APP_AES_KEY;
const AuthContext = createContext(null)

export const AuthProvider = ({children}) =>{
    const [user, setUser] = useState(null)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const checklogin = () =>{
        
    }

    const login = (user, pass) =>{
        setUser(user)
        setIsLoggedIn(true)
        localStorage.setItem("isLoggedIn", true)
        localStorage.setItem("user", user);
        localStorage.setItem("pass", CryptoJS.AES.encrypt(pass, AES_KEY).toString())
    }

    const logout = () =>{
        setUser(null)
        setIsLoggedIn(false)
        localStorage.removeItem("isLoggedIn");
        //console.log("isloggedin")
        localStorage.removeItem("user")
        localStorage.removeItem("pass")
    }

    return (
        <AuthContext.Provider value={{user, isLoggedIn, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export const  useAuth = () =>{
    return useContext(AuthContext)
} 