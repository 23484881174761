
import Login from './components/Login'
import LoginSuccess from './components/LoginSuccess';
import './App.css';
import Protected from './Protected'
import { BrowserRouter, Navigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/auth';
import { useState } from 'react';
import Header from './components/Header';
import LogAnalysis from './components/LogAnalysis';

function App() {
  //const [isLoggedIn, setIsLoggedIn] = useState(null);
  const auth = useAuth()
  return (
    <AuthProvider>  
      <BrowserRouter>
      <Header/>
      <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/dashboard" element={<Protected><LoginSuccess /></Protected>} />
          <Route path="/log-analysis" element={<Protected><LogAnalysis /></Protected>} />
          <Route path="/*" exact={true} element={<Navigate to="/"/>}/>    
      </Routes>
    </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
