import { useNavigate } from 'react-router-dom';
import AerxLogo from '../Images/AerXLogo.png'
import LogoutLogo from '../Images/icon-logout.png'
import { useAuth } from '../context/auth';
import './Header.css'

function Header(){
    const auth = useAuth()

    const navigate = useNavigate();

    const handleLogout = () =>{
        auth.logout();
        navigate("/" , {replace : true})
    }

    return(
        <div className='header'>
        <img src={AerxLogo} alt='logo' className='img-Logo'></img>
                    {localStorage.getItem("isLoggedIn") && 
                    <button title="Logout" className='btn-logout' onClick={handleLogout}>
                        <img src={LogoutLogo} alt='logo' className='logout-img'></img>
                    </button>}
        </div> 
        );
    
}
export default Header;