// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis-wrap {
    font-family: 'IBM-Plex-sans';
    min-height: calc(100vh- 60px);
    max-width: 100%;
    max-height: 90vh;
    background-color: #fff;
    padding: 0
}

.top-section {
    background-color: #f5f8ff;
}

.d-flex {
    display: flex
}

.heading {
    width: 95%;
    text-align: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/LogAnalysis.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB;AACJ;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;AACJ;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".analysis-wrap {\n    font-family: 'IBM-Plex-sans';\n    min-height: calc(100vh- 60px);\n    max-width: 100%;\n    max-height: 90vh;\n    background-color: #fff;\n    padding: 0\n}\n\n.top-section {\n    background-color: #f5f8ff;\n}\n\n.d-flex {\n    display: flex\n}\n\n.heading {\n    width: 95%;\n    text-align: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
