import { useEffect,useState } from "react";
import PreTO from "./Phases/PreTakeOff";
import CruisePhase from "./Phases/CruisePhase";
import LandingPhase from "./Phases/LandingPhase";
import TakeOFF from "./Phases/Takeoff";
import ClimbPhase from "./Phases/ClimbPhase";
import DescentPhase from "./Phases/DescentPhase";
import ApproaChPhase from "./Phases/Approach";


export default function CircuitComponent(props) {
    const [IsCircuitVisible, setIsCircuitVisible] = useState(false);

    const preTakeOff = props.ciruitData.data["Pre T/O Phase"];
    const cruisePhasaData = props.ciruitData.data["Cruise Phase"];
    const LandingPhaseData = props.ciruitData.data["Landing Phase"];
    const TakeOffData = props.ciruitData.data["Takeoff Phase"];
    const ClimbPhaseData = props.ciruitData.data["Climb Phase"];
    const DescentPhaseData = props.ciruitData.data["Descent Phase"];
    const ApproachData = props.ciruitData.data["Approach Phase"];
    const crNo = props.ciruitData.crNO;
    // console.log(LandingPhase);

    useEffect(() => {
        //SeparatePhases();
    }, []);
    useEffect(() => {
        setIsCircuitVisible(props.ciruitData.isCircuitVisible ? true : false);
    }, [props.ciruitData.isCircuitVisible])
    return (
        <div className={"circuit-graphs-wrap " + (IsCircuitVisible ? '' : 'd-none')}>

            <PreTO data={preTakeOff} crNO={crNo} />
            <TakeOFF data={TakeOffData} crNO={crNo} />
            <ClimbPhase data={ClimbPhaseData} crNO={crNo} />
            <CruisePhase data={cruisePhasaData} crNO={crNo} />
            <DescentPhase data={DescentPhaseData} crNO={crNo} />
            <ApproaChPhase data={ApproachData} crNO={crNo} />
            <LandingPhase data={LandingPhaseData} crNO={crNo} />

        </div>
    )
}