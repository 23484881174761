import { useState, useEffect } from "react";
import { Scatter } from "react-chartjs-2";
import { AltColor, AxisBorderColor, AxisGridColor, AxisTickColor, AxisTitleColor, GSspeedColor, IASColor, IdealColor, LowerLimitColor, Pitchcolor, RunwayColor, UpperLimitcolor, VsColor, XToucDownColor, sds } from "../../../ColorConst/Graphcolors";

import "./LandingPhase.css"
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  Filler,

} from 'chart.js';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  Filler,

);


export default function LandingPhase(props) {

  const [pitchProfileData, setPitchProfileData] = useState();
  const [pitchPrStatus, setpitchPrStatus] = useState(false);

  const [speedProfileData, setSpeedProfiledata] = useState();
  const [speedProfileStatus, setSpeedProfileStatus] = useState(false);
  const [SpdReamars, setSpdReamars] = useState();

  const [AltiProifileData, setAltiProifileData] = useState();
  const [AltiProifileStatus, setAltiProifileStatus] = useState(false);
  const [AltRemark, setAltRemark] = useState();

  const [FlareProfileData, setFlareProfileData] = useState();
  const [FlareProfileStatus, setFlareProfileStatus] = useState(false);


  const [RollOutData, setRollOutData] = useState();
  const [RollOutStatus, setRollOutStatus] = useState(false);
  const [IsPhaseVisible, setIsPhaseVisible] = useState(false);
  const crNO = props.crNO;
  function Plot() {
    let landingPh = props.data;
    setIsPhaseVisible(props.data.isVisible ? true : false);
    if (landingPh.landing_detected === 1) {
      if (landingPh["Pitch Profile"].status === 1) {

        let xValues = landingPh["Pitch Profile"].x;
        let dataArray = [];
        for (let index = 0; index < xValues.length; index++) {
          if (xValues[index] <= 1) {
            let tempXY = {
              x: xValues[index],
              y: landingPh["Pitch Profile"].y[index]
            }
            dataArray.push(tempXY);
          }
        }

        // For runway
        let minValue = Math.min(...landingPh["Pitch Profile"].y);

        let runwayY = 0;
        let axisTarget = 0;
        if (minValue < -5) {
          runwayY = -9;
          axisTarget = -10;
        }
        else if (minValue >= -5) {
          runwayY = -4;
          axisTarget = -5;
        }
        else {
          runwayY = minValue + 5;
          axisTarget = minValue;
        }
        let runwayData = [{ x: xValues[xValues.length - 1], y: runwayY }, { x: 0, y: runwayY }];
       
        let upperLimitData=[{x:dataArray[0].x, y:9}, {x:dataArray[dataArray.length-1].x, y:9}]
        let lowerLimitData=[{x:dataArray[0].x, y:2}, {x:dataArray[dataArray.length-1].x, y:2}]
        let xtouchDown=landingPh["Pitch Profile"].x_touchdown;
        let toudownData=[{x:xtouchDown, y:runwayY},{x:xtouchDown,y:10}]
        
        let pitchTempData = {
          datasets: [
            {
              label: 'Pitch',
              data: dataArray,
              showLine: true,
              fill: false,
              borderColor: Pitchcolor,
              pointRadius: 0.5,
      
            },
          
            {
              label: 'Upper Limit',
              data: upperLimitData,
              showLine: true,
              fill: false,
              borderColor: UpperLimitcolor,
              pointRadius: 0.5,
              borderDash: [10, 5],
            },
            {
              label: 'Lower Limit',
              data: lowerLimitData,
              showLine: true,
              fill: false,
              borderColor: LowerLimitColor,
              pointRadius: 0.5,
              borderDash: [10, 5],
            },
            {
              label: 'Touch Down Line',
              data: toudownData,
              showLine: true,
              fill: false,
              borderColor:XToucDownColor,
              pointRadius: 0.5,
              borderDash: [10, 5],
            },
            {
              label: '',
              data: runwayData,
              showLine: true,
              backgroundColor: 'rgba(0,0,0,0)',
              borderColor: 'rgba(0,0,0,0)',
              fill: {
                target: { value: axisTarget },
                above: RunwayColor,
                below: RunwayColor
              },
              pointRadius: 0,


            },



          ]


        }

        setPitchProfileData(pitchTempData);
        setpitchPrStatus(true);
      }

      if (landingPh["Speed Profile"].status === 1) {

        let xValues = landingPh["Speed Profile"].x;
        let dataArray = [];
        for (let index = 0; index < xValues.length; index++) {
          if (xValues[index] <= 1) {
            let tempXY = {
              x: xValues[index],
              y: landingPh["Speed Profile"].y[index]
            }
            dataArray.push(tempXY);
          }
        }

        // For runway
        let minValue = Math.min(...landingPh["Speed Profile"].y);
        let runwayY = Math.floor(minValue / 10)

        runwayY = (runwayY * 10) + 1.5;

        let runwayData = [{ x: -0.4, y: runwayY }, { x: 0, y: runwayY }];
        let upperLimitData=[{x:dataArray[0].x, y:75}, {x:-0.4, y:75}]
        let lowerLimitData=[{x:dataArray[0].x, y:70}, {x:-0.4, y:70}]
        let xtouchDown=landingPh["Speed Profile"].x_touchdown;
        let toudownData=[{x:xtouchDown, y:runwayY},{x:xtouchDown,y:80}]
        let spdTempdata = {
          datasets: [
            {
              label: 'IAS',
              data: dataArray,
              showLine: true,
              fill: false,
              borderColor: IASColor,
              pointRadius: 1.1,
            },
            {
              label: 'Upper Limit',
              data: upperLimitData,
              showLine: true,
              fill: false,
              borderColor: UpperLimitcolor,
              pointRadius: 0.5,
              borderDash: [10, 5],
            },
            {
              label: 'Lower Limit',
              data: lowerLimitData,
              showLine: true,
              fill: false,
              borderColor: LowerLimitColor,
              pointRadius: 0.5,
              borderDash: [10, 5],
            },
            {
              label: 'Touch Down Line',
              data: toudownData,
              showLine: true,
              fill: false,
              borderColor:XToucDownColor,
              pointRadius: 0.5,
              borderDash: [10, 5],
            },
            {
              label: '',
              data: runwayData,
              showLine: true,
              backgroundColor: 'rgba(0,0,0,0)',
              borderColor: 'rgba(0,0,0,0)',
              fill: {
                target: 'origin',
                above: RunwayColor,
              },
              pointRadius: 0,
            }

          ]
        }

        setSpeedProfiledata(spdTempdata);
        setSpeedProfileStatus(true);
        setSpdReamars(landingPh["Speed Profile"].auto_comment);
      }

      if (landingPh["Altitude Profile"].status === 1) {
        let landingAltitudeX = landingPh["Altitude Profile"].x;

        let landingAltitudeY = landingPh["Altitude Profile"].y;

        let dataArray = [];
        for (let index = 0; index < landingAltitudeX.length; index++) {
          let tempData = {
            x: landingAltitudeX[index],
            y: landingAltitudeY[index]
          }
          dataArray.push(tempData);
        }


        let runwayFillData = [{ x: -0.4, y: landingAltitudeY[landingAltitudeY.length - 1] }, { x: 0, y: landingAltitudeY[landingAltitudeY.length - 1] }]

        let LandingIdealGrapghData = landingPh["Altitude Profile"].ideal;


        let IdealData = [{ x: 0, y: LandingIdealGrapghData[LandingIdealGrapghData.length - 1] },
        { x: landingAltitudeX[0], y: LandingIdealGrapghData[0] }]

        let LowerLimitData = landingPh["Altitude Profile"].lower_limit;
        let lowerData = [{ x: 0, y: LowerLimitData[LowerLimitData.length - 1] },
        { x: landingAltitudeX[0], y: LowerLimitData[0] }]

        let upperLimitData = landingPh["Altitude Profile"].upper_limit;
        let upperData = [{ x: 0, y: upperLimitData[upperLimitData.length - 1] },
        { x: landingAltitudeX[0], y: upperLimitData[0] }]


        let TempLAndAltitudeData = {
          datasets: [
            {
              label: 'Actual path',
              data: dataArray,
              showLine: true,
              fill: false,
              borderColor: AltColor,
              pointRadius: 1.1,
              // borderWidth:5

            },
            {
              label: 'Ideal',
              data: IdealData,
              showLine: true,
              fill: false,
              borderColor: IdealColor,
              borderDash: [10, 5],

            },
            {
              label: 'Lower Limit',
              data: lowerData,
              showLine: true,
              fill: false,
              borderColor: LowerLimitColor,
              borderDash: [10, 5],

            },
            {
              label: 'Upper Limit',
              data: upperData,
              showLine: true,
              borderColor: UpperLimitcolor,
              fill: false,
              borderDash: [10, 5],

            },
            {
              label: '',
              data: runwayFillData,
              showLine: true,
              backgroundColor: 'rgba(0,0,0,0)',
              borderColor: 'rgba(0,0,0,0)',
              fill: {
                target: 'origin',
                above: '#888888',
                below: 'rgb(0, 0, 255)'
              },
              pointRadius: 0,
            }
          ]
        }
        setAltiProifileData(TempLAndAltitudeData);
        setAltiProifileStatus(true);
        setAltRemark(landingPh["Altitude Profile"].auto_comment)

      }

      if (landingPh["Flare Profile"].status === 1) {


        let landingAltitudeX = landingPh["Flare Profile"].x;
        let landingAltitudeY = landingPh["Flare Profile"].y_1;


        let dataArray = [];
        let dataArrayTwo = [];
        for (let index = 0; index < landingAltitudeX.length; index++) {
          let tempData = {
            x: landingAltitudeX[index],
            y: landingAltitudeY[index]
          }

          let tempVSdata = {
            x: landingAltitudeX[index],
            y: landingPh["Flare Profile"].y_2[index]
          }
          dataArray.push(tempData);
          dataArrayTwo.push(tempVSdata);
        }

        let yMin = Math.min(...landingPh["Flare Profile"].y_2);
        let yMax = Math.max(...landingPh["Flare Profile"].y_2);
        let YmaxTrun = Math.trunc(yMax / 200);
        let YminTrun = Math.trunc(yMin / 200)
        yMax = (YmaxTrun + 1) * 200;
        yMin = (YminTrun - 1) * 200;
        
        let XlineData = [{ x: landingPh["Flare Profile"].x_touchdown, y: yMax }, { x: landingPh["Flare Profile"].x_touchdown, y: yMin },]
        let YlineData = [{ x: landingAltitudeX[0], y: 0 }, { x: landingAltitudeX[landingAltitudeX.length - 1], y: 0 }]
        let TempFlaredata = {
          datasets: [
            {
              label: 'Pitch',
              data: dataArray,
              borderColor: Pitchcolor,
              backgroundColor: Pitchcolor,
              showLine: true,
              fill: false,
              yAxisID: 'y',
              pointRadius: 0.5,
              borderWidth: 2,
            },
            {
              label: 'Vspeed',
              data: dataArrayTwo,
              borderColor: VsColor,
              backgroundColor: VsColor,
              showLine: true,
              fill: false,
              yAxisID: 'y1',
              pointRadius: 0.5,
              borderWidth: 2,
            },
            {
              label: 'Touch Down',
              data: XlineData,
              borderColor: XToucDownColor,
              backgroundColor: XToucDownColor,
              showLine: true,
              fill: false,
              yAxisID: 'y1',
              pointRadius: 0,
              borderWidth: 2,
              borderDash: [10, 5]
            },
            {
              label: 'Vs Zero Line',
              data: YlineData,
              borderColor: 'black',
              backgroundColor: 'black',
              showLine: true,
              fill: false,
              yAxisID: 'y1',
              pointRadius: 0,
              borderWidth: 2,
              borderDash: [10, 5]
            },
          ],
        };

        setFlareProfileData(TempFlaredata);
        setFlareProfileStatus(true);


      }
      if (landingPh["Roll Out"].status === 1) {

        let landingAltitudeX = landingPh["Roll Out"].x;
        let landingAltitudeY = landingPh["Roll Out"].y_1;


        let dataArray = [];
        let dataArrayTwo = [];
        for (let index = 0; index < landingAltitudeX.length; index++) {
          let tempData = {
            x: landingAltitudeX[index],
            y: landingAltitudeY[index]
          }

          let tempVSdata = {
            x: landingAltitudeX[index],
            y: landingPh["Roll Out"].y_2[index]
          }
          dataArray.push(tempData);
          dataArrayTwo.push(tempVSdata);
        }

        let yMin = Math.min(...landingPh["Roll Out"].y_1);
        let yMax = Math.max(...landingPh["Roll Out"].y_1);
        let modmax = yMax % 5;
        let modMin = yMin % 5;
        if (modmax !== 0) {
          let YmaxTrun = Math.trunc(yMax / 5);
          yMax = (YmaxTrun * 5) + 5;
        }
        if (modMin !== 0) {
          let YminTrun = Math.trunc(yMin / 5)
          yMin = (YminTrun * 5) - 5;
        }
        let XlineData = [{ x: landingPh["Roll Out"].x_touchdown, y: yMax }, { x: landingPh["Roll Out"].x_touchdown, y: yMin },]

        let TempFlaredata = {
          datasets: [
            {
              label: 'Altitude',
              data: dataArray,
              borderColor: AltColor,
              backgroundColor: AltColor,
              showLine: true,
              fill: false,
              yAxisID: 'y',
              pointRadius: 0.4,
              borderWidth: 2,
            },
            {
              label: 'IAS',
              data: dataArrayTwo,
              borderColor: IASColor,
              backgroundColor: IASColor,
              showLine: true,
              fill: false,
              yAxisID: 'y1',
              pointRadius: 0.4,
              borderWidth: 2,
            },
            {
              label: 'Touch Down',
              data: XlineData,
              borderColor: XToucDownColor,
              backgroundColor: 'rgba(255, 99, 255, 1)',
              showLine: true,
              fill: false,
              yAxisID: 'y',
              pointRadius: 0,
              borderWidth: 2,
              borderDash: [10, 5]
            },

          ],
        };


        setRollOutData(TempFlaredata);
        setRollOutStatus(true);


      }
    }
  }

  const LandPitchDataOption = {
    responsive: true,
    scales: {
      x: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,

        },
        title: {
          display: true,
          text: "Distance from Runway (NM)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { color: AxisTickColor, maxTicksLimit: 6 },
        title: {
          display: true,
          text: "Pitch (deg)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },

    layout: {
      padding: {
        // top: 5,
        // left: 15,
        // right: 15,
        // bottom: 15
      }
    }
  }
  const SpeedProfileOptions = {
    responsive: true,
    scales: {
      x: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,

        },
        title: {
          display: true,
          text: "Distance from Runway (NM)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { stepSize: 5, color: AxisTickColor, maxTicksLimit: 6 },
        title: {
          display: true,
          text: "IAS (kt)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },

    layout: {
      padding: {
        // top: 5,
        // left: 15,
        // right: 15,
        // bottom: 15
      }
    }
  }

  const LandAltiOptions = {
    scales: {
      x: {
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,
        },

        title: {
          display: true,
          text: "Distance From Runway (NM)",
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { color: AxisTickColor },
        title: {
          display: true,
          text: "AltMSL (ft)",
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },
    //   tooltips: {
    //     mode: 'index',
    //     intersect: false,
    //   },
    //  hover: {
    //     mode: 'nearest',
    //     intersect: false
    //   },

  }


  const FlareOptions = {
    stacked: false,
    scales: {
      x: {
        //    type:"number",// <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,
          // callback: function (val, index) {
          //   let num = this.getLabelForValue(val);
          //   let num1 = num.toFixed(0);
          //   return num1;
          // },
        },
        border: {
          color: AxisBorderColor,
          width: 12,

        },
        title: {
          display: true,
          text: "Distance Over Runway (ft)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',

        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        title: {
          display: true,
          text: "Pitch (deg)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
          stepSize: 200,
        },
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },

        title: {
          display: true,
          text: "Vertical Speed (fpm)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
    },

    plugins: {

      legend: {
        display: true,
        position: "top",
        useBorderRadius: true,
        //maxHeight:50,
        // align:"end",
        labels: {
          boxHeight: 2,
          boxWidth: 13,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        }
      },
    }
  }


  const RollOUtOptions = {
    stacked: false,
    scales: {
      x: {  // <-- axis is not array anymore, unlike before in v2.x: '[{'
        grid: {
          color: AxisGridColor,
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,

        },
        border: {
          color: AxisBorderColor,
          width: 12,

        },
        title: {
          display: true,
          text: "Distance Over Runway (ft)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y: {
        display: true,
        position: 'left',
        grid: {
          color: AxisGridColor,
        },
        ticks: {
          stepSize: 5,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        title: {
          display: true,
          text: "AltMSL (ft)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',

        ticks: {
          color: AxisTickColor, stepSize: 5

        },
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },

        title: {
          display: true,
          text: "IAS (kt)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      },
    },
    plugins: {

      legend: {
        display: true,
        position: "top",
        useBorderRadius: true,
        //maxHeight:50,
        // align:"end",
        labels: {
          boxHeight: 2,
          boxWidth: 12,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        }
      },
    }
  }

  useEffect((e) => {
    Plot();
  }, []);
  useEffect(() => {
    setIsPhaseVisible(props.data.isVisible ? true : false);
  }, [props.data.isVisible])
  return (
    <div className={IsPhaseVisible ? '' : 'd-none'}>
      <div >
        {pitchPrStatus &&
          <div className="single-graph-wrapper" id={`cr${crNO}_Land_PitchWr`} key={`cr${crNO}_Land_PitchWr`} >
            <div className="GraphHeading" key={`cr${crNO}_Land_PitchWrHDG`} >Circuit {crNO} Landing Phase: Pitch Profile</div>
            <Scatter id={`cr${crNO}_Land_Pitch`} data={pitchProfileData} options={LandPitchDataOption} key={`cr${crNO}_Land_Pitch`}/>

          </div>
        }
        {speedProfileStatus &&
          <div className="single-graph-wrapper" id={`cr${crNO}_Land_SPDWr`} key={`cr${crNO}_Land_SPDWr`}>
            <div className="GraphHeading" key={`cr${crNO}_Land_SPDHDG`} >Circuit {crNO} Landing Phase: Speed Profile</div>
            <Scatter id={`cr${crNO}_Land_SPD`} data={speedProfileData} options={SpeedProfileOptions} key={`cr${crNO}_Land_SPD`}/>
            <div className={`remarks ${SpdReamars === "Satisfactory" ? "satisfactory" : "notsatisfactory"}`} key={`cr${crNO}_Land_SPDRm`}>Remark: {SpdReamars}</div>
          </div>
        }
        {AltiProifileStatus &&
          <div className="single-graph-wrapper" id={`cr${crNO}_Land_Altwr`} key={`cr${crNO}_Land_Altwr`}>
            <div className="GraphHeading" key={`cr${crNO}_Land_AltHDG`} >Circuit {crNO} Landing Phase: Altitude Profile</div>
            <Scatter id={`cr${crNO}_Land_Alt`} data={AltiProifileData} options={LandAltiOptions} key={`cr${crNO}_Land_Alt`} />
            <div className={`remarks ${AltRemark === "Satisfactory" ? "satisfactory" : "notsatisfactory"}`} key={`cr${crNO}_Land_AltRm`}>Remark: {AltRemark}</div>
          </div>
        }
        {FlareProfileStatus &&
          <div className="single-graph-wrapper" id={`cr${crNO}_Land_FlareWr`} key={`cr${crNO}_Land_FlareWr`}>
            <div className="GraphHeading" key={`cr${crNO}_Land_FlareHDG`}>Circuit {crNO} Landing Phase: Flare Profile</div>
            <Scatter id={`cr${crNO}_Land_Flare`} data={FlareProfileData} options={FlareOptions} key={`cr${crNO}_Land_Flare`} />

          </div>
        }
        {RollOutStatus &&
          <div className="single-graph-wrapper" id={`cr${crNO}_Land_RollWr`} key={`cr${crNO}_Land_RollWr`} >
            <div className="GraphHeading"  key={`cr${crNO}_Land_RollHDG`}>Circuit {crNO} Landing Phase: Roll Out</div>
            <Scatter id={`cr${crNO}_Land_Roll`} data={RollOutData} options={RollOUtOptions} key={`cr${crNO}_Land_Roll`} />

          </div>
        }
      </div>
    </div>
  )
}