import { Line, } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
// import "../ElevationGraph.css";
import CircuitComponent from "./Circuit";
import "./GraphParent.css";
import _ from 'lodash';
import { AltColor, AxisGridColor, AxisBorderColor, AxisTickColor, AxisTitleColor, GSspeedColor, UpperLimitcolor, LowerLimitColor } from "../../ColorConst/Graphcolors";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    Filler,
} from 'chart.js';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Title,
    Tooltip,
    Filler
);



export default function GraphsComponent(props) {
    //Elevation data
    const [elevationX, setElevationX] = useState();
    const [elevationY, setElevationY] = useState();
    const [elevationStatus, setelevationStatus] = useState(false);

    const [CircuitData, setCircuitData] = useState([]);

    const [LastTaxiX, setLastTaxiX] = useState();
    const [LastTaxiY, setLastTaxiY] = useState([]);
    const [LastTaxiStatus, setLastTaxiStatus] = useState(false);
    const [LastTaxiRemarks, setLastTaxiRemarks] = useState();

    const [phasesFilterItems, setPhasesFilterItems] = useState([]);
    const [DisplayedPhasesFilterItems, setDisplayedPhasesFilterItems] = useState([]);
    const [CircuitFilterSelected, setCircuitFilterSelected] = useState("all");

    const onPhaseFilterItemSelected = (selectedPhaseItem) => {
        selectedPhaseItem.isSelected = !selectedPhaseItem.isSelected;
        setDisplayedPhasesFilterItems([...DisplayedPhasesFilterItems]);
        const selectedPhasesArr = _.filter(DisplayedPhasesFilterItems, (phaseFilter) => phaseFilter.isSelected);
        const isFilterApplied = selectedPhasesArr.length > 0;
        if (isFilterApplied && CircuitFilterSelected == "all") {
            const selectedPhaseNamesArr = selectedPhasesArr.map((phase) => phase.phaseName);
            CircuitData.map((circuit) => {
                phasesFilterItems.forEach((phase) => {
                    if (selectedPhaseNamesArr.includes(phase.phaseName) && circuit.data[phase.phaseName]) {
                        circuit.data[phase.phaseName].isVisible = true;
                    } else if (!selectedPhaseNamesArr.includes(phase.phaseName) && circuit.data[phase.phaseName]) {
                        circuit.data[phase.phaseName].isVisible = false;
                    }
                })
            })
        } else if (isFilterApplied && CircuitFilterSelected !== "all") {
            const selectedPhaseNamesArr = selectedPhasesArr.map((phase) => phase.phaseName);
            const selectedCircuit = CircuitData.find(circuit => circuit.circuitName === CircuitFilterSelected);
            phasesFilterItems.forEach((phase) => {
                if (selectedPhaseNamesArr.includes(phase.phaseName) && selectedCircuit.data[phase.phaseName]) {
                    selectedCircuit.data[phase.phaseName].isVisible = true;
                } else if (!selectedPhaseNamesArr.includes(phase.phaseName) && selectedCircuit.data[phase.phaseName]) {
                    selectedCircuit.data[phase.phaseName].isVisible = false;
                }
            })
        } else {
            CircuitData.map((circuit) => {
                resetPhasesVisibilityInCircuit(circuit);
            })
        }

        setCircuitData([...CircuitData]);
    };

    const resetCircuitVisibility = () => {
        CircuitData.map((circuit) => {
            circuit.isCircuitVisible = true;
            return circuit;
        })
    }

    const resetPhasesVisibilityInCircuit = (circuit) => {
        phasesFilterItems.forEach((phase) => {
            if (circuit.data[phase.phaseName]) {
                circuit.data[phase.phaseName].isVisible = true;
            }
        })
    }

    const resetCircuitPhaseFilterChips = (circuit) => {
        circuit.phasesNames.map((phase) => {
            phase.isSelected = false;
            return phase;
        })
        return circuit;
    }


    function SeparateData() {
        //    let jsonFile = jsCross;
        let jsonFile = props.result;
        //console.log(jsonFile)
        let elevationXvalues = jsonFile["Flight Elevation Profile"].x;
        let FlightElevationGraphstatus = jsonFile["Flight Elevation Profile"].status;
        if (FlightElevationGraphstatus === 1) {
            let xArray = [];
            for (let index = 0; index < elevationXvalues.length; index++) {
                const element = elevationXvalues[index].toString().split(" ");
                xArray.push(element[1]);
            }
            setElevationX(xArray);
            setElevationY(jsonFile["Flight Elevation Profile"].y);
            setelevationStatus(true);
        }

        let circuitDataArray = [];
        let phasesFiterKeys = [];
        for (let index = 1; index <= jsonFile.Total_circuits; index++) {
            let circuit = "Circuit " + index;
            let phasesNamesArr = Object.keys(jsonFile[circuit]);
            const filteredPhaseNamesArr = phasesNamesArr.filter((phaseName) => {
                let count=jsonFile[circuit][phaseName]?.count;
                if(count<=0)  return false;
                return true;
            })
            const phasesArr = filteredPhaseNamesArr.map((phaseName) => {
                jsonFile[circuit][phaseName] = { ...jsonFile[circuit][phaseName], isVisible: true };
                return {
                    phaseName: phaseName,
                    isSelected: false
                }
            })
            phasesFiterKeys = _.unionBy(phasesFiterKeys, phasesArr, "phaseName");
            let circuitDataIndex = {
                crNO: index,
                data: jsonFile[circuit],
                circuitName: circuit,
                phasesNames: phasesArr,
                isCircuitVisible: true
            }
            circuitDataArray.push(circuitDataIndex);
        }
        setPhasesFilterItems(phasesFiterKeys);
        setDisplayedPhasesFilterItems(phasesFiterKeys);
        setCircuitData(circuitDataArray);

        let lastTaxiData = jsonFile["Post Landing Phase"]["Taxi Speed: Post Final Landing"];
        if (lastTaxiData.status === 1 && lastTaxiData.touch_and_go === 0) {
            let lastTaxiDataX = lastTaxiData.x;
            let xArray = [];
            let upperData = [];
            let lowerData = []
            for (let index = 0; index < lastTaxiDataX.length; index++) {
                let element = lastTaxiDataX[index].toString().split(" ");
                xArray.push(element[1]);
                upperData.push(15);
                lowerData.push(10)
            }
        

           let yDataArray = [lastTaxiData.y, upperData, lowerData]
         
            setLastTaxiX(xArray);
            setLastTaxiY(yDataArray);
            setLastTaxiStatus(true);
            setLastTaxiRemarks(lastTaxiData.auto_comment);
        }

        // console.log(circuitDataArray);
    }


    const ElevationData = {
        labels: elevationX,
        datasets: [{
            label: 'Altitude',
            data: elevationY,
            borderColor: AltColor,
            ///  panGaps: true,
            borderWidth: 2,
            pointRadius: 0,
            fill: {
                target: 'origin',
                above: 'rgba(120, 86, 255, 0.2)',   // Area will be red above the origin
                //   below: 'rgb(0, 0, 255)'    // And blue below the origin
            },

        },

        ]
    }
    const ElevOpt = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    color: AxisGridColor,
                    /// drawOnChartArea:false,
                },
                ticks: {
                    color: AxisTickColor, maxTicksLimit: 9,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Lcl Time (HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },

            },
            y: {
                grid: {
                    color: AxisGridColor,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                ticks: { color: AxisTickColor },

                title: {
                    display: true,
                    text: "AltMSL (ft) ",
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            }
        },
        // maintainAspectRatio: false,
        layout: {
            padding: {
                top: 5,
                left: 5,
                right: 45,
                bottom: 15
            }
        },
        plugins: {
            title: {
                display: false,
                text: 'Custom Chart Title'
            },
            chartAreaBorder: {
                borderColor: 'red',
                borderWidth: 2,
                borderDash: [5, 5],
                borderDashOffset: 2,
            }
        }

    }
    const LastTaxiData = {
        labels: LastTaxiX,
        datasets: [
            {
                label: 'Taxi Speed',
                data: LastTaxiY[0],
                borderColor: GSspeedColor,
                ///  panGaps: true,
                borderWidth: 2,
                pointRadius: 0,
                fill: {
                    target: 'origin',
                    above: 'rgba(202, 128, 220, 0.2)',
                    //   below: 'rgb(0, 0, 255)'    // And blue below the origin
                },
            },
            {
                label: 'Upper Limit',
                data: LastTaxiY[1],
                borderColor: UpperLimitcolor,
                ///  panGaps: true,
                borderWidth: 2,
                pointRadius: 0,
                fill: false,
                borderDash: [10, 5]
            },
            {
                label: 'Lower Limit',
                data: LastTaxiY[2],
                borderColor: LowerLimitColor,
                ///  panGaps: true,
                borderWidth: 2,
                pointRadius: 0,
                fill: false,
                borderDash: [10, 5]
            }

        ]
    }
    const TaxiOPt = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    color: AxisGridColor,
                    /// drawOnChartArea:false,
                },
                ticks: {
                    color: AxisTickColor, maxTicksLimit: 9,
                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                title: {
                    display: true,
                    text: "Lcl Time(HH:MM:SS)",
                    fontSize: 100,
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },

            },
            y: {
                grid: {
                    color: AxisGridColor,
                    //  drawOnChartArea:false,

                },
                border: {
                    color: AxisBorderColor,
                    height: "2px"
                },
                ticks: { color: AxisTickColor },

                title: {
                    display: true,
                    text: "Ground Speed (kt) ",
                    color: AxisTitleColor,
                    font: {
                        size: 12,
                        family: 'IBM-Plex-sans'
                    }
                },
            }
        },
        // maintainAspectRatio: false,
        layout: {
            padding: {
                top: 5,
                left: 5,
                right: 45,
                bottom: 15
            }
        },
        plugins: {
            title: {
                display: false,
                text: 'Custom Chart Title'
            },
            chartAreaBorder: {
                borderColor: 'red',
                borderWidth: 2,
                borderDash: [5, 5],
                borderDashOffset: 2,
            }
        }

    }


    useEffect(() => {
        SeparateData();
    }, []);

    useEffect(() => {
        setDisplayedPhasesFilterItems(phasesFilterItems);
    }, [phasesFilterItems]);

    useEffect(() => {
        const onChangeCircuitFilter = () => {
            CircuitData.map((circuit) => {
                resetPhasesVisibilityInCircuit(circuit);
                const updatedCircuit = resetCircuitPhaseFilterChips(circuit);
                return updatedCircuit;
            })
            if (CircuitFilterSelected === 'all') {
                resetCircuitVisibility();
                setDisplayedPhasesFilterItems([...phasesFilterItems]);
            } else {
                const selectedCircuit = CircuitData.find(circuit => circuit.circuitName === CircuitFilterSelected);
                setDisplayedPhasesFilterItems([...selectedCircuit.phasesNames]);
                CircuitData.map((circuit) => {
                    if (circuit.circuitName === selectedCircuit.circuitName) {
                        circuit.isCircuitVisible = true;
                    } else {
                        circuit.isCircuitVisible = false;
                    }
                })
            }
        }
        onChangeCircuitFilter();
    }, [CircuitFilterSelected]);
    return (
        <div>
            <div className="top-wrap">
                <div className="GraphContainer">
                    <section className="graph-filters-wrap">
                        <div className="circuit-filter-wrap">
                            <select id="circuit-drpdown"
                                className={"circuit-filter " + (CircuitData.length >= 2 ? "" : "d-none")} value={CircuitFilterSelected}
                                onChange={e => setCircuitFilterSelected(e.target.value)}>
                                <option value="all">All Circuits</option>
                                {CircuitData.map(circuitItem => (
                                    <option value={circuitItem.circuitName}>{circuitItem.circuitName}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="phases-filter-wrap">
                            {DisplayedPhasesFilterItems.map(phasesFilterItem => (
                                <div 
                                    className={"graph-filter-item " + (phasesFilterItem.isSelected ? 'selected' : '')}
                                    onClick={() => onPhaseFilterItemSelected(phasesFilterItem)}>
                                    {phasesFilterItem.phaseName}
                                </div>
                            ))
                            }
                        </div>
                    </section>
                    <>
                        {elevationStatus &&
                            <div className="single-graph-wrapper" >
                                <div className="GraphHeading" >Elevation profile</div>
                                <Line id="EleGraph" data={ElevationData} options={ElevOpt} key="EleGraph" />
                            </div>
                        }

                        {CircuitData.map(e => (
                            <div className={e.isCircuitVisible ? "" : "d-none"}>
                                <CircuitComponent ciruitData={e} key={e} />
                            </div>
                        ))
                        }
                        {LastTaxiStatus &&
                            <div className="single-graph-wrapper">
                                <div className="GraphHeading" >Post Landing Phase: Taxi Speed Profile</div>
                                <Line id="lastTaxiGraph" data={LastTaxiData} options={TaxiOPt} />
                                <div className={`remarks ${LastTaxiRemarks === "Satisfactory" ? "satisfactory" : "notsatisfactory"}`}>Remark: {LastTaxiRemarks}</div>
                            </div>
                        }

                    </>

                </div>
            </div>
            {/* <div className="GraphContainer">
                {CircuitData.map(e => (
                    <div>
                        <CircuitComponent ciruitData={e} />
                    </div>
                ))
                }

            </div> */}
        </div>
    )
}