import { useEffect, useState } from "react";
import { Line,  } from "react-chartjs-2";
import { AxisBorderColor, AxisGridColor, AxisTickColor, GSspeedColor, AxisTitleColor, UpperLimitcolor, LowerLimitColor } from "../../../ColorConst/Graphcolors";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  Filler,
} from 'chart.js';
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  Filler
);


export default function PreTO(props) {

  const [xAxisData, setxAxisData] = useState();
  const [yAxisData, setYAxisdata] = useState();
  const [upperData, setUpperData] = useState();
  const [lowerData, setLowerData] = useState();
  const [PreTaxiStatus, SetPreTaxiStatus] = useState(false)
  const [TaxSpdRemark, setTaxSpdRemark] = useState([]);
  const [IsPhaseVisible, setIsPhaseVisible] = useState(false);


  const crNO = props.crNO;
  function PlotTO() {
    let preToData = props.data;
    setIsPhaseVisible(props.data.isVisible ? true : false);
    if (preToData["Taxi Speed: Pre Takeoff"].status === 1 && preToData["Taxi Speed: Pre Takeoff"].touch_and_go === 0) {
      let xValues = preToData["Taxi Speed: Pre Takeoff"].x;
      let xArray = [];
      let xUpper = [];
      let xLower = [];
      for (let index = 0; index < xValues.length; index++) {
        let xVal = xValues[index].split(" ");
        xArray.push(xVal[1]);
        xUpper.push(15);
        xLower.push(10)

      }
      setxAxisData(xArray);
      setUpperData(xUpper);
      setLowerData(xLower)
      setYAxisdata(preToData["Taxi Speed: Pre Takeoff"].y);
      SetPreTaxiStatus(true);
      setTaxSpdRemark(preToData["Taxi Speed: Pre Takeoff"].auto_comment);
    }
  }

  const PTOdata = {
    labels: xAxisData,
    datasets: [
      {
        label: 'Ground Speed',
        data: yAxisData,
        borderColor: GSspeedColor,
        ///  panGaps: true,
        borderWidth: 2,
        pointRadius: 0,
      },
      {
        label: 'Upper Limit',
        data: upperData,
        showLine: true,
        borderColor: UpperLimitcolor,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        borderDash: [10, 5],


      },
      {
        label: 'Lower Limit',
        data: lowerData,
        showLine: true,
        fill: false,
        borderWidth: 2,
        borderColor: LowerLimitColor,
        borderDash: [10, 5],
        pointRadius: 0,

      },


    ]
  }
  const PreGrdOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: {
          color: AxisTickColor, maxTicksLimit: 9,
        },
        title: {
          display: true,
          text: "Elapsed Time (HH:MM:SS)",
          fontSize: 100,
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },

      },
      y: {
        grid: {
          color: AxisGridColor,
        },
        border: {
          color: AxisBorderColor,
          height: "2px"
        },
        ticks: { color: AxisTickColor },
        title: {
          display: true,
          text: "Ground speed (kt) ",
          color: AxisTitleColor,
          font: {
            size: 12,
            family: 'IBM-Plex-sans'
          }
        },
      }
    },
    // maintainAspectRatio: false,
    layout: {
      padding: {
        top: 5,
        left: 5,
        right: 45,
        bottom: 15
      }
    },
    plugins: {
      title: {
        display: false,
        text: 'Custom Chart Title'
      }
    }

  }

  useEffect((e) => {
    PlotTO();
  }, []);
  useEffect(() => {
    setIsPhaseVisible(props.data.isVisible ? true : false);
  }, [props.data.isVisible])
  return (
    <div className={IsPhaseVisible ? '' : 'd-none'}>
      {PreTaxiStatus &&
        <div data-phase="takeOffPhase" data-circuitno={crNO}
          className="single-graph-wrapper "
          id={`cr${crNO}_PTO_Taxwr`} key={`cr${crNO}_PTO_Taxwr`} >
          <div className="GraphHeading" key={`cr${crNO}_PTO_TaxHDG`} >Circuit {crNO} Pre take off phase: Taxi speed profile  {PTOdata.isVisible}</div>
          <Line id={`cr${crNO}_PTO_Tax`} data={PTOdata} options={PreGrdOptions} key={`cr${crNO}_PTO_Tax`} />
          <div className={`remarks ${TaxSpdRemark === "Satisfactory" ? "satisfactory" : "notsatisfactory"}`} key={`cr${crNO}_PTO_TaxRm`}>Remark: {TaxSpdRemark}</div>
        </div>
      }
    </div>
  )

}